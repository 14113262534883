"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var Translator_1 = require("@/plugins/Translator");
var vue_3 = require("@ionic/vue");
var vue_4 = require("vue");
var Auth_1 = require("@/stores/Auth");
/*----- auth store -----*/
exports.default = (0, vue_1.defineComponent)({
    __name: 'DivisionSwitcher',
    setup: function (__props) {
        var authStore = (0, Auth_1.useAuthStore)();
        var currentDivision = (0, vue_4.computed)(function () { return authStore.activeDivision; });
        /*----- texts -----*/
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var titleText = (0, vue_4.computed)(function () { return i18n.t("settings.division.title"); });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonItem), null, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(titleText)), 1)
                        ]; }),
                        _: 1
                    }),
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), { slot: "end" }, {
                        default: (0, vue_2.withCtx)(function () {
                            var _a;
                            return [
                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((_a = (0, vue_2.unref)(currentDivision)) === null || _a === void 0 ? void 0 : _a.description), 1)
                            ];
                        }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
