"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_router_1 = require("@ionic/vue-router");
var AuthMiddleware_1 = require("@/router/middleware/AuthMiddleware");
var ServiceWorker_1 = require("@/plugins/ServiceWorker/ServiceWorker");
var ModuleMiddleware_1 = require("@/router/middleware/ModuleMiddleware");
var ModuleList_1 = require("@/models/settings/ModuleList");
var routes = [
    {
        path: "/",
        redirect: "/home",
    },
    {
        path: "/index.html",
        redirect: "/home",
    },
    {
        name: "welcome",
        path: "/welcome",
        component: function () { return Promise.resolve().then(function () { return require("@/views/WelcomePage.vue"); }); },
    },
    {
        name: "maintenance",
        path: "/maintenance",
        component: function () { return Promise.resolve().then(function () { return require("@/views/MaintenancePage.vue"); }); },
    },
    {
        path: "/",
        component: function () { return Promise.resolve().then(function () { return require("@/components/navigation/BottomNavigation.vue"); }); },
        children: [
            {
                name: "home",
                path: "home",
                component: function () { return Promise.resolve().then(function () { return require("@/views/DashboardPage.vue"); }); },
            },
            /*----------------------*/
            /*----- module zew -----*/
            /*----------------------*/
            {
                name: "zew",
                path: "modules/zew",
                component: function () { return Promise.resolve().then(function () { return require("@/views/modules/zeitwirtschaft/TimeManagementPage.vue"); }); },
                meta: {
                    middleware: [new AuthMiddleware_1.default(), new ModuleMiddleware_1.default(ModuleList_1.ModuleList.TIME_MANAGEMENT)],
                },
            },
            /*----- sub pages -----*/
            {
                name: "zewNotifications",
                path: "modules/zew/notifications",
                component: function () { return Promise.resolve().then(function () { return require("@/views/modules/zeitwirtschaft/NotificationsPage.vue"); }); },
                meta: {
                    middleware: [new AuthMiddleware_1.default(), new ModuleMiddleware_1.default(ModuleList_1.ModuleList.TIME_MANAGEMENT)],
                },
            },
            {
                name: "absences",
                path: "modules/zew/absences",
                component: function () { return Promise.resolve().then(function () { return require("@/views/modules/zeitwirtschaft/AbsencePage.vue"); }); },
                meta: {
                    middleware: [new AuthMiddleware_1.default(), new ModuleMiddleware_1.default(ModuleList_1.ModuleList.TIME_MANAGEMENT)],
                },
            },
            {
                name: "addAbsence",
                path: "modules/zew/absences/add",
                component: function () { return Promise.resolve().then(function () { return require("@/views/modules/zeitwirtschaft/AddAbsencePage.vue"); }); },
                meta: {
                    middleware: [new AuthMiddleware_1.default(), new ModuleMiddleware_1.default(ModuleList_1.ModuleList.TIME_MANAGEMENT)],
                },
            },
            /*---------------------------------*/
            /*----- Modul Aufgabenplanung -----*/
            /*---------------------------------*/
            {
                name: "todo",
                path: "modules/todo",
                component: function () { return Promise.resolve().then(function () { return require("@/views/modules/aufgabenplanung/TodoPage.vue"); }); },
                meta: {
                    middleware: [new AuthMiddleware_1.default(), new ModuleMiddleware_1.default(ModuleList_1.ModuleList.TODO)],
                },
            },
            {
                name: "todo-detail",
                path: "modules/todo/:id",
                component: function () { return Promise.resolve().then(function () { return require("@/views/modules/aufgabenplanung/TodoDetailPage.vue"); }); },
                meta: {
                    middleware: [new AuthMiddleware_1.default(), new ModuleMiddleware_1.default(ModuleList_1.ModuleList.TODO)],
                },
            },
            /*---------------------------------*/
            /*--------- Einstellungen ---------*/
            /*---------------------------------*/
            {
                name: "settings",
                path: "settings",
                component: function () { return Promise.resolve().then(function () { return require("@/views/SettingsPage.vue"); }); },
            },
        ],
        // default middleware if not specified
        meta: {
            middleware: [new AuthMiddleware_1.default()],
        },
    },
    {
        path: "/modules",
        component: function () { return Promise.resolve().then(function () { return require("@/components/navigation/BottomNavigation.vue"); }); },
        children: [
            /*----- module access denied -----*/
            {
                name: "moduleAccessDenied",
                path: "access-denied",
                component: function () { return Promise.resolve().then(function () { return require("@/views/errors/ModuleAccessDenied.vue"); }); },
            },
        ],
    },
    /*----- 404 error -----*/
    {
        name: "notFound",
        path: "/:catchAll(.*)",
        component: function () { return Promise.resolve().then(function () { return require("@/views/errors/NotFound.vue"); }); },
    },
];
var router = (0, vue_router_1.createRouter)({
    history: (0, vue_router_1.createWebHistory)(process.env.BASE_URL),
    routes: routes,
});
var serviceWorker = ServiceWorker_1.default.getInstance();
router.beforeEach(function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
    var middlewares, _i, middlewares_1, middleware;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!to.meta.middleware) {
                    return [2 /*return*/, next()];
                }
                middlewares = to.meta.middleware;
                _i = 0, middlewares_1 = middlewares;
                _a.label = 1;
            case 1:
                if (!(_i < middlewares_1.length)) return [3 /*break*/, 4];
                middleware = middlewares_1[_i];
                return [4 /*yield*/, middleware.execute(to, from, next)];
            case 2:
                if (!(_a.sent())) {
                    return [2 /*return*/];
                }
                _a.label = 3;
            case 3:
                _i++;
                return [3 /*break*/, 1];
            case 4:
                if (!(to.name === "home" || to.name === "settings")) return [3 /*break*/, 6];
                return [4 /*yield*/, serviceWorker.updateRegistration()];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6:
                next();
                return [2 /*return*/];
        }
    });
}); });
exports.default = router;
