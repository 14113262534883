"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMaxWebStore = void 0;
var pinia_1 = require("pinia");
exports.useMaxWebStore = (0, pinia_1.defineStore)({
    id: "maxWebStore",
    state: function () { return ({
        packageVersion: process.env.VUE_APP_VERSION || "0",
    }); },
    getters: {
        appVersion: function (state) {
            return state.packageVersion;
        },
    },
});
