"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var English = {
    settings: {
        title: "Settings",
        theme: {
            title: "Theme",
            placeholder: "choose a theme",
            themes: {
                dark: "dark",
                light: "light",
            },
        },
        language: {
            title: "Language",
            placeholder: "choose a language",
        },
        mandant: {
            title: "Client",
            placeholder: "choose a client",
        },
        division: {
            title: "Division",
            placeholder: "not assigned",
        },
        logout: {
            title: "logout",
        },
        notifications: {
            saveSuccess: "Settings @:notifications.save.success",
            saveInfo: "",
            saveError: "Could not be saved, please try again",
            logout: "Successfully logged out",
        },
    },
    maintenance: {
        title: "Maintenance",
        description: "Please try again in a few minutes",
    },
    modules: {
        title: "Module",
        zew: {
            moduleDescription: { title: "Time management" },
            currentTimeRecord: { loggedIn: "logged in since:", loggedOut: "logged out:" },
            signIn: "sign in",
            signOut: "sign out",
            timeRecord: { hours: "hours" },
            absence: {
                name: "Absence | Absences",
                type: "Type",
                start: "Start date",
                end: "End date",
                message: "Description",
                hours: "Hours",
                entireDay: "All-day",
                supervisor: "Supervisor",
                create: "@:actions.create @:modules.zew.absence.name",
            },
            notifications: {
                title: "Messages",
            },
        },
        todo: { moduleDescription: { title: "task management" } }
    },
    general: {
        details: "Details",
        history: "History",
    },
    transactions: {
        message: "message",
        inProgress: "in progress",
        approved: "approved",
        rejected: "rejected",
    },
    account: {
        welcomeBack: "welcome back",
    },
    actions: {
        exit: "close",
        back: "back",
        save: "save",
        cancel: "cancel",
        create: "create",
        read: "read",
        update: "update",
        delete: "delete",
        select: "choose",
        search: "search",
        reject: "reject",
        accept: "accept",
    },
    notifications: {
        save: {
            success: "successfully saved",
            info: "",
            error: "",
        },
        delete: {
            success: "successfully deleted",
            info: "",
            error: "could not be deleted please try again",
        },
        update: {
            success: "successfully updated",
            denied: "This entry cannot be edited anymore",
            error: "could not be updated please try again",
        },
        api: {
            connectionFailed: "Connection failed, try again later",
            connectionRestored: "Connection re-established...",
        },
        sync: {
            entity: { error: "Record not yet synchronized. <br/> Please try again in a few minutes" },
            module: {
                active: "Synchronizing, please try again later. <br/> start: {content}",
            },
        },
    },
    alerts: { delete: { title: "Confirm delete", message: "Delete {content} ?" } },
};
exports.default = English;
