"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("@ionic/vue");
var router_1 = require("@/router");
var vue_4 = require("vue");
var Translator_1 = require("@/plugins/Translator");
/*----- texts -----*/
exports.default = (0, vue_1.defineComponent)({
    __name: 'AbsenceButton',
    setup: function (__props) {
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var name = (0, vue_4.computed)(function () { return i18n.t("modules.zew.absence.name", 2); });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonNavLink), { "router-direction": "forward" }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButton), {
                        color: "light",
                        onClick: _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.unref)(router_1.default).push({ name: 'absences' })); })
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(name)), 1)
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
