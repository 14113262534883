"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("@ionic/vue");
var DateFormatter_1 = require("@/plugins/DateFormatter");
var TodoChips_vue_1 = require("@/components/modules/aufgabenplanung/TodoChips.vue");
var router_1 = require("@/router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TodoLogItem',
    props: {
        todo: null
    },
    setup: function (__props) {
        var props = __props;
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonItem), {
                button: true,
                detail: true,
                onClick: _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.unref)(router_1.default).push({ name: 'todo-detail', params: { id: __props.todo.todoLogId } })); })
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonText), null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)(TodoChips_vue_1.default, { todo: __props.todo }, null, 8, ["todo"]),
                                    (0, vue_2.createElementVNode)("h2", null, (0, vue_2.toDisplayString)(__props.todo.title), 1),
                                    (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)((0, vue_2.unref)(DateFormatter_1.default).formatDateShort(__props.todo.inDate)) + " - " + (0, vue_2.toDisplayString)(__props.todo.company), 1)
                                ]; }),
                                _: 1
                            }),
                            (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(__props.todo.infoMessage), 1)
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
