"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWelcomeStore = void 0;
var pinia_1 = require("pinia");
exports.useWelcomeStore = (0, pinia_1.defineStore)({
    id: "welcomeStore",
    state: function () { return ({
        slides: [
            {
                id: 1,
                title: "Max® CRM/ERP Software",
                description: "",
                image: "/assets/logos/max-logo.png",
            },
        ],
        authSlide: {
            id: 3,
            title: "Anmelden um fortzufahren",
            description: "",
            image: "",
        },
    }); },
    getters: {},
    actions: {},
});
