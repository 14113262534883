<script setup>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle, IonItem, IonLabel,
  IonContent, IonButtons, IonButton, IonText, IonList
} from "@ionic/vue";
import {computed} from "vue";
import Translator from "@/plugins/Translator";
import TodoLogsController from "@/controllers/modules/aufgabenplanung/TodoLogsController";
import {useRoute} from "vue-router";
import BackButton from "@/components/common/buttons/BackButton.vue";

/*----- texts -----*/
const translator = Translator.getInstance();
const i18n = translator.getI18nGlobalInstance();
const moduleDescription = computed(() => i18n.t("modules.todo.moduleDescription.title"));

const controller = new TodoLogsController();

// ID aus parameter lesen
const route = useRoute();
const todoId = route.params.id;

const todo = await controller.getTodoLog(todoId);

</script>


<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar :fixed="false">
        <ion-title></ion-title>
        <ion-buttons slot="start">
          <ion-button>
            <back-button default-href="/modules/todo"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar :fixed="false">
          <ion-title size="large" class="uk-text-left">
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <!----- content ----->

      <div class="uk-container">
        <ion-text><h1>{{ todo.title }}</h1></ion-text>
        <ion-text><h2>Kundeninformationen</h2></ion-text>
        <ion-list>
          <ion-item>
            <ion-label>Firma, Name</ion-label>
            <ion-text>
              {{ todo.company }}
            </ion-text>
          </ion-item>
          <ion-item>
            <ion-label>E-Mail</ion-label>
            <ion-text>
              {{ todo.email }}
            </ion-text>
          </ion-item>
          <ion-item>
            <ion-label>Telefon</ion-label>
            <ion-text>
              {{ todo.telG }}
            </ion-text>
          </ion-item>
        </ion-list>
        <div class="spacer"></div>
        <ion-text><h2>Vorgang, Info</h2></ion-text>
        <ion-text><p>{{ todo.infoMessage }}</p></ion-text>
        <div class="spacer"></div>
        <ion-text><h2>Fehler / Meldung des Kunden</h2></ion-text>
        <ion-text><p>{{ todo.errorMessage }}</p></ion-text>
        <div class="spacer"></div>
        <ion-text><h2>Zuordnung</h2></ion-text>

        <ion-list>
          <ion-item>
            <ion-label>Abteilung</ion-label>
            <ion-text>
              {{ todo.group }}
            </ion-text>
          </ion-item>
          <ion-item>
            <ion-label>Zuständige Person</ion-label>
            <ion-text>
              {{ todo.aSybUser.firstName }} {{ todo.aSybUser.lastName }}
            </ion-text>
          </ion-item>
          <ion-item>
            <ion-label>Todo erstellt von</ion-label>
            <ion-text>
              {{ todo.iSybUser.firstName }} {{ todo.iSybUser.lastName }}
            </ion-text>
          </ion-item>
        </ion-list>

      </div>

    </ion-content>
  </ion-page>
</template>

<style scoped>
.spacer {
  height: 1rem;
}
</style>
