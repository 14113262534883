"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryKeys = void 0;
/**
 * Query keys, werden für vue-query verwendet um queries zu cachen
 */
var QueryKeys;
(function (QueryKeys) {
    /*----- module zew queries -----*/
    QueryKeys["TIME_RECORDS_HISTORY"] = "timerecords-history";
    QueryKeys["TIME_RECORDS_CURRENT"] = "timerecords-current";
    QueryKeys["ABSENCES_HISTORY"] = "absences-history";
    QueryKeys["ABSENCES_PENDING"] = "absences-pending";
    QueryKeys["TODO_LOGS"] = "todo-logs";
})(QueryKeys = exports.QueryKeys || (exports.QueryKeys = {}));
