"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("@ionic/vue");
var icons_1 = require("ionicons/icons");
var AuthController_1 = require("@/controllers/AuthController");
var ModuleList_1 = require("@/models/settings/ModuleList");
var vue_4 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BottomNavigation',
    setup: function (__props) {
        var isActive = true;
        var hasZewAccess = (0, vue_4.computed)(function () { return AuthController_1.default.hasModuleAccess(ModuleList_1.ModuleList.TIME_MANAGEMENT); });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonPage), null, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTabs), null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRouterOutlet)),
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTabBar), { slot: "bottom" }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTabButton), {
                                                tab: "Home",
                                                href: "/home"
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (_ctx.$route.path == '/home')
                                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                                                            key: 0,
                                                            class: (0, vue_2.normalizeClass)({ divider: isActive })
                                                        }, null, 2))
                                                        : (0, vue_2.createCommentVNode)("", true),
                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonIcon), { icon: (0, vue_2.unref)(icons_1.homeOutline) }, null, 8, ["icon"])
                                                ]; }),
                                                _: 1
                                            }),
                                            ((0, vue_2.unref)(hasZewAccess))
                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonTabButton), {
                                                    key: 0,
                                                    tab: "Zew",
                                                    href: "/modules/zew"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (_ctx.$route.path == '/modules/zew')
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                                                                key: 0,
                                                                class: (0, vue_2.normalizeClass)({ divider: isActive })
                                                            }, null, 2))
                                                            : (0, vue_2.createCommentVNode)("", true),
                                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonIcon), { icon: (0, vue_2.unref)(icons_1.timerOutline) }, null, 8, ["icon"])
                                                    ]; }),
                                                    _: 1
                                                }))
                                                : (0, vue_2.createCommentVNode)("", true),
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTabButton), {
                                                tab: "Settings",
                                                href: "/settings"
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (_ctx.$route.path === '/settings')
                                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                                                            key: 0,
                                                            class: (0, vue_2.normalizeClass)({ divider: isActive })
                                                        }, null, 2))
                                                        : (0, vue_2.createCommentVNode)("", true),
                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonIcon), { icon: (0, vue_2.unref)(icons_1.settingsOutline) }, null, 8, ["icon"])
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
