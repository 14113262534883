"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-65dc2802"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "uk-flex todo-chips" };
var vue_3 = require("@ionic/vue");
var vue_4 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TodoChips',
    props: {
        todo: null
    },
    setup: function (__props) {
        var props = __props;
        // Hintergrundfarbe anhand des Codes setzen
        var getStatusColor = (0, vue_4.computed)(function () {
            var _a;
            if (((_a = props.todo) === null || _a === void 0 ? void 0 : _a.status) == "A")
                return "success";
            else
                return "";
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonChip), { color: (0, vue_2.unref)(getStatusColor) }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.todo.status), 1)
                    ]; }),
                    _: 1
                }, 8, ["color"]),
                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonChip), { color: "medium" }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.todo.group), 1)
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonChip), { color: "medium" }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.todo.assignedSybUser), 1)
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
