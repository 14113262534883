"use strict";
/* eslint-disable no-console */
Object.defineProperty(exports, "__esModule", { value: true });
var register_service_worker_1 = require("register-service-worker");
var ServiceWorker_1 = require("@/plugins/ServiceWorker/ServiceWorker");
if (process.env.NODE_ENV === "production") {
    (0, register_service_worker_1.register)("".concat(process.env.BASE_URL, "service-worker.js"), {
        ready: function (registration) {
            console.log("App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB");
            if (registration) {
                var serviceWorker = ServiceWorker_1.default.getInstance();
                serviceWorker.setRegistration(registration);
            }
        },
        registered: function () {
            console.log("Service worker has been registered.");
        },
        cached: function () {
            console.log("Service worker Content has been cached for offline use.");
        },
        updatefound: function (registration) {
            console.log("Service worker New content is downloading.", registration);
            //const serviceWorker = ServiceWorker.getInstance();
            //serviceWorker.setRegistration(registration);
        },
        updated: function (registration) {
            console.log("Servive worker New content is available; please refresh.");
            document.dispatchEvent(new CustomEvent("swUpdated", { detail: registration }));
        },
        offline: function () {
            console.log("Service worker No internet connection found. App is running in offline mode.");
        },
        error: function (error) {
            console.error("Service worker Error during service worker registration:", error);
        },
    });
}
