"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var Translator_1 = require("@/plugins/Translator");
var vue_3 = require("@ionic/vue");
var vue_4 = require("vue");
var vee_validate_1 = require("vee-validate");
var AbsencesController_1 = require("@/controllers/modules/zeitwirtschaft/AbsencesController");
var Supervisor_1 = require("@/models/Supervisor");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AbsenceSupervisor',
    props: {
        modelValue: null,
        error: null,
        meta: null
    },
    emits: ["update:modelValue"],
    setup: function (__props, _a) {
        var _this = this;
        var emit = _a.emit;
        var props = __props;
        /*----- texts -----*/
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var supervisorText = (0, vue_4.computed)(function () { return i18n.t("modules.zew.absence.supervisor"); });
        var selectText = (0, vue_4.computed)(function () { return i18n.t("actions.select"); });
        var searchText = (0, vue_4.computed)(function () { return i18n.t("actions.search"); });
        var update = function (message) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                emit('update:modelValue', message);
                return [2 /*return*/];
            });
        }); };
        var controller = new AbsencesController_1.default();
        var supervisors = null;
        var filteredItems = (0, vue_4.ref)(null);
        (0, vue_4.onBeforeMount)(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, controller.getSupervisors()];
                    case 1:
                        supervisors = _a.sent();
                        filteredItems.value = supervisors;
                        return [2 /*return*/];
                }
            });
        }); });
        var filterList = function (searchQuery) {
            /**
             * If no search query is defined,
             * return all options.
             */
            if (searchQuery === undefined || searchQuery === null) {
                filteredItems.value = [];
            }
            else {
                /**
                 * Otherwise, normalize the search
                 * query and check to see which items
                 * contain the search query as a substring.
                 */
                var normalizedQuery_1 = searchQuery.toLowerCase();
                if (supervisors == null)
                    return;
                filteredItems.value = supervisors ? supervisors.filter(function (supervisor) {
                    return supervisor.getFullName().toLowerCase().includes(normalizedQuery_1);
                }) : [];
            }
        };
        var selectedSupervisor = (0, vue_4.ref)(null);
        var selectedText = (0, vue_4.ref)("");
        var search = function (event) {
            filterList(event.target.value);
        };
        var setSupervisor = function (event) {
            var data = event.detail.value;
            selectedSupervisor.value = new Supervisor_1.default(data);
            selectedText.value = selectedSupervisor.value.getFullName();
            update(selectedSupervisor.value.divisionUserId.toString());
            vue_3.modalController.dismiss();
            ;
        };
        return function (_ctx, _cache) {
            var _directive_validation_class_names = (0, vue_2.resolveDirective)("validation-class-names");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, [
                (0, vue_2.createVNode)((0, vue_2.unref)(vee_validate_1.Field), { name: "supervisor" }, {
                    default: (0, vue_2.withCtx)(function (_a) {
                        var meta = _a.meta;
                        return [
                            (0, vue_2.withDirectives)(((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonItem), { id: "select-supervisor" }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(supervisorText)), 1)
                                        ]; }),
                                        _: 1
                                    }),
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonInput), {
                                        slot: "end",
                                        tabindex: "-1"
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(selectedSupervisor.value !== null && selectedSupervisor.value.value !== null ? selectedSupervisor.value.getFullName() : (0, vue_2.unref)(selectText)), 1)
                                        ]; }),
                                        _: 1
                                    }),
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonNote), { slot: "error" }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.error), 1)
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 2
                            }, 1024)), [
                                [_directive_validation_class_names, meta]
                            ])
                        ];
                    }),
                    _: 1
                }),
                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonModal), {
                    ref: "modal",
                    trigger: "select-supervisor",
                    "initial-breakpoint": 0.3,
                    breakpoints: [0, 0.3, 0.5, 0.75]
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), { class: "ion-padding" }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSearchbar), {
                                    autofocus: false,
                                    onClick: _cache[0] || (_cache[0] = function ($event) { return (_ctx.$refs.modal.$el.setCurrentBreakpoint(0.5)); }),
                                    placeholder: (0, vue_2.unref)(searchText),
                                    onIonInput: _cache[1] || (_cache[1] = function ($event) { return (search($event)); })
                                }, null, 8, ["placeholder"]),
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonList), { inset: true }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRadioGroup), {
                                            onIonChange: _cache[2] || (_cache[2] = function ($event) { return (setSupervisor($event)); }),
                                            id: "supervisor",
                                            name: "supervisor"
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(filteredItems.value, function (supervisor) {
                                                    return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonItem), {
                                                        key: supervisor.id
                                                    }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonAvatar), { slot: "start" }, {
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonImg), {
                                                                        class: "avatar",
                                                                        src: "assets/icons/avatar.svg"
                                                                    })
                                                                ]; }),
                                                                _: 1
                                                            }),
                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), null, {
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createElementVNode)("h2", null, (0, vue_2.toDisplayString)(supervisor.getFullName()), 1),
                                                                    (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(supervisor.divisionCode) + " - " + (0, vue_2.toDisplayString)(supervisor.divisionDescription), 1)
                                                                ]; }),
                                                                _: 2
                                                            }, 1024),
                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRadio), {
                                                                value: supervisor,
                                                                checked: ""
                                                            }, null, 8, ["value"])
                                                        ]; }),
                                                        _: 2
                                                    }, 1024));
                                                }), 128))
                                            ]; }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }, 8, ["initial-breakpoint", "breakpoints"])
            ], 64));
        };
    }
});
