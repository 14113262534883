"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapGroupBy = exports.objectGroupBy = void 0;
var objectGroupBy = function (array, property) {
    return array.reduce(function (grouped, element) {
        var _a;
        return (__assign(__assign({}, grouped), (_a = {}, _a[element[property]] = __spreadArray(__spreadArray([], (grouped[element[property]] || []), true), [element], false), _a)));
    }, {});
};
exports.objectGroupBy = objectGroupBy;
/**
 * map an array of objects by key, value
 * @param items V = Value type
 * @param key K = Key type - grouping by this key
 */
function mapGroupBy(array, grouper) {
    if (array == null || array == undefined)
        return new Map();
    return array.reduce(function (map, item) {
        // get the value we're grouping by
        var key = grouper(item);
        if (!map.has(key)) {
            // set the first item to the map
            map.set(key, [item]);
        }
        else {
            // add the item to the map
            var element = map.get(key);
            if (element)
                element.push(item);
        }
        return map;
    }, new Map());
}
exports.mapGroupBy = mapGroupBy;
