"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-4cfa87f0"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "uk-container" };
var _hoisted_2 = ["src"];
var _hoisted_3 = { class: "text" };
var _hoisted_4 = { class: "text" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'WelcomeSlide',
    props: {
        title: null,
        description: null,
        image: null
    },
    setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("img", { src: __props.image }, null, 8, _hoisted_2),
                (0, vue_2.createElementVNode)("h1", _hoisted_3, (0, vue_2.toDisplayString)(__props.title), 1),
                (0, vue_2.createElementVNode)("h1", _hoisted_4, (0, vue_2.toDisplayString)(__props.description), 1)
            ]));
        };
    }
});
