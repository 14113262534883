"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var ControllerBase_1 = require("@/controllers/ControllerBase");
var ModuleList_1 = require("@/models/settings/ModuleList");
var vue_1 = require("vue");
var DateFormatter_1 = require("@/plugins/DateFormatter");
var Util_1 = require("@/plugins/Util");
var vue_query_1 = require("vue-query");
var QueryKeys_1 = require("@/models/settings/QueryKeys");
var TodoLogsController = /** @class */ (function (_super) {
    __extends(TodoLogsController, _super);
    function TodoLogsController() {
        var _this = _super.call(this) || this;
        _this.moduleRoute = "modules/aufgabenplanung/";
        _this.moduleId = ModuleList_1.ModuleList.TODO;
        return _this;
    }
    /**
     * Gibt die TodoLog Einträge des Mandanten zurück
     * über optionale Parameter im `searchQuery` können die Daten gefiltert werden
     * @see TodoLogsController im Backend
     * @param searchQuery
     */
    TodoLogsController.prototype.getTodoLogs = function (searchQuery) {
        if (searchQuery === void 0) { searchQuery = ""; }
        return __awaiter(this, void 0, Promise, function () {
            var mandant, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentMandant()];
                    case 1:
                        mandant = _e.sent();
                        if (!mandant)
                            return [2 /*return*/, null];
                        _b = (_a = this.api.httpClient).get;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(mandant.mandantId, "/todos").concat(searchQuery)];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(), _d)]))];
                    case 3:
                        response = _e.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Gibt der entsprechende TodoLog-Eintrag des Mandanten zurück
     * @param todoLogId
     */
    TodoLogsController.prototype.getTodoLog = function (todoLogId) {
        return __awaiter(this, void 0, Promise, function () {
            var mandant, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentMandant()];
                    case 1:
                        mandant = _e.sent();
                        if (!mandant)
                            return [2 /*return*/, null];
                        _b = (_a = this.api.httpClient).get;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(mandant.mandantId, "/todos/").concat(todoLogId)];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(), _d)]))];
                    case 3:
                        response = _e.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Gibt die TodoGruppen des Mandanten zurück
     */
    TodoLogsController.prototype.getTodoGroups = function () {
        return __awaiter(this, void 0, Promise, function () {
            var mandant, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentMandant()];
                    case 1:
                        mandant = _e.sent();
                        if (!mandant)
                            return [2 /*return*/, null];
                        _b = (_a = this.api.httpClient).get;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(mandant.mandantId, "/todogroups")];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(), _d)]))];
                    case 3:
                        response = _e.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * TodoLogs formatieren und gruppieren
     * @param todoListModel
     */
    TodoLogsController.formatTodoLogs = function (todoListModel) {
        if (todoListModel == null || todoListModel.logs == null)
            return null;
        // Ursprüngliches Objekt wiederherstellten
        var todoList = (0, vue_1.toRaw)(todoListModel);
        // Erstellungsdatum korrekt formatieren
        for (var _i = 0, _a = todoList.logs; _i < _a.length; _i++) {
            var todo = _a[_i];
            if (todo.inDate !== undefined)
                todo.inDay = DateFormatter_1.default.formatDbDay(todo.inDate);
        }
        // Daten nach Erstellungsdatum gruppieren
        var data = (0, Util_1.mapGroupBy)(todoList.logs, function (todo) { return todo.inDate; });
        return data;
    };
    /*----- queries -----*/
    TodoLogsController.prototype.useTodoLogsQuery = function (searchQuery) {
        var _this = this;
        return (0, vue_query_1.useInfiniteQuery)([QueryKeys_1.QueryKeys.TODO_LOGS], function () { return _this.getTodoLogs(searchQuery.value); }, {
            getNextPageParam: function (lastPage, pages) { return lastPage === null || lastPage === void 0 ? void 0 : lastPage.pagination.hasNextPage; },
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        });
    };
    return TodoLogsController;
}(ControllerBase_1.default));
exports.default = TodoLogsController;
