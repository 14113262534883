"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var German = {
    settings: {
        title: "Einstellungen",
        theme: {
            title: "Theme",
            placeholder: "Theme auswählen",
            themes: {
                dark: "Dunkel",
                light: "Hell",
            },
        },
        language: {
            title: "Sprache",
            placeholder: "Sprache auswählen",
        },
        mandant: {
            title: "Mandant",
            placeholder: "Mandant auswählen",
        },
        division: {
            title: "Abteilung",
            placeholder: "nicht zugewiesen",
        },
        logout: {
            title: "abmelden",
        },
        notifications: {
            saveSuccess: "Einstellungen @:notifications.save.success",
            saveInfo: "",
            saveError: "",
            logout: "Erfolgreich abgemeldet",
        },
    },
    maintenance: {
        title: "Wartungsarbeiten",
        description: "Bitte versuchen Sie es in ein paar Minuten erneut",
    },
    modules: {
        title: "Modul",
        zew: {
            moduleDescription: { title: "Zeitwirtschaft" },
            currentTimeRecord: { loggedIn: "angemeldet seit:", loggedOut: "nicht angemeldet" },
            signIn: "kommen",
            signOut: "gehen",
            timeRecord: { hours: "Stunden" },
            absence: {
                name: "Absenz | Absenzen",
                type: "Typ",
                start: "Datum von",
                end: "Datum bis",
                message: "Beschreibung",
                hours: "Stunden",
                entireDay: "Ganztägig",
                supervisor: "Vorgesetzte",
                create: "@:modules.zew.absence.name @:actions.create",
            },
            notifications: {
                title: "Benachrichtigungen",
            },
        },
        todo: {
            moduleDescription: { title: "Aufgabenplanung" }
        }
    },
    general: {
        details: "Details",
        history: "History",
    },
    transactions: {
        message: "Mitteilung",
        inProgress: "In Bearbeitung",
        approved: "Bewilligt",
        rejected: "Abgelehnt",
    },
    account: {
        welcomeBack: "Willkommen zurück",
    },
    actions: {
        exit: "schliessen",
        back: "zurück",
        save: "speichern",
        cancel: "abbrechen",
        create: "erstellen",
        read: "ansehen",
        update: "aktualisieren",
        delete: "löschen",
        select: "auswählen",
        search: "suchen",
        reject: "ablehnen",
        accept: "akzeptieren",
    },
    notifications: {
        save: {
            success: "erfolgreich gespeichert",
            info: "",
            error: "Fehler beim Speichern, bitte erneut versuchen",
        },
        delete: {
            success: "erfolgreich gelöscht",
            info: "",
            error: "Konnte nicht gelöscht werden, bitte erneut versuchen",
        },
        update: {
            success: "Erfolgreich aktualisiert",
            denied: "Dieser Eintrag kann nicht mehr bearbeitet werden",
            error: "Fehler beim aktualisieren, bitte erneut versuchen",
        },
        api: {
            connectionFailed: "Verbindung fehlgeschlagen, versuchen Sie es später noch einmal",
            connectionRestored: "Verbindung wieder hergestellt...",
        },
        sync: {
            entity: {
                error: "Datensatz noch nicht synchronisiert. <br/> Bitte in einigen Minuten erneut versuchen",
            },
            module: {
                active: "Wird synchronisiert, bitte später erneut versuchen. <br/> Start: {content}",
            },
        },
    },
    alerts: { delete: { title: "Löschen bestätigen", message: "{content} wirklich löschen?" } },
};
exports.default = German;
