"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var ControllerBase_1 = require("@/controllers/ControllerBase");
var router_1 = require("@/router");
var Notifier_1 = require("@/plugins/notifications/Notifier");
var Auth_1 = require("@/stores/Auth");
/**
 * Controller für die Authentifizierung / Autorisierung
 *
 * @see MaxWebBackend AuthController
 * @see MaxWebBackend UsersController
 */
var AuthController = /** @class */ (function (_super) {
    __extends(AuthController, _super);
    function AuthController() {
        return _super.call(this) || this;
    }
    /**
     * Benutzer anmelden mit E-Mail, Passwort
     *
     * @remarks POST: api/auth/login
     * @returns IAuthResponse oder StatusCode bei Fehler
     */
    AuthController.prototype.login = function (user) {
        return __awaiter(this, void 0, Promise, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.httpClient.post("auth/login", user)];
                    case 1:
                        response = _a.sent();
                        console.log("login response", response);
                        if (response == undefined)
                            return [2 /*return*/, null];
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Benutzer abmelden und den authStore löschen
     *
     * @remarks Keine REST Abfrage, die Tokens werden lokal gelöscht
     * @returns Weiterleitung zu "welcome" Seite
     */
    AuthController.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authStore.ClearStorage()];
                    case 1:
                        _a.sent();
                        Notifier_1.default.showSuccess({ message: this.i18n.t("settings.notifications.logout") });
                        //
                        return [2 /*return*/, router_1.default.replace({ name: "welcome" })];
                }
            });
        });
    };
    /**
     * Gibt den eigenen WebUser zurück
     *
     * @remarks GET: api/users/me
     * @returns WebUser
     */
    AuthController.prototype.getMe = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        console.log("getMe");
                        _b = (_a = this.api.httpClient).get;
                        _c = ["users/me"];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(), _d)]))];
                    case 2:
                        response = _e.sent();
                        console.log("response", response);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Gibt alle Mandanten zurück, bei denen der WebUser registriert ist
     *
     * @remarks GET: api/users/mandanten
     * @returns WebUser
     */
    AuthController.prototype.getMandanten = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        console.log("getMandanten");
                        _b = (_a = this.api.httpClient).get;
                        _c = ["users/mandanten"];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 2:
                        response = _e.sent();
                        console.log("getMandanten response", response.data);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Überprüfen, ob der aktive Mandant auf das Modul zugreifen darf
     */
    AuthController.hasModuleAccess = function (moduleId) {
        var store = (0, Auth_1.useAuthStore)();
        var mandant = store.activeMandant;
        if (mandant == null || mandant.mandantModules == null)
            return false;
        for (var _i = 0, _a = mandant.mandantModules; _i < _a.length; _i++) {
            var mandantModule = _a[_i];
            if (mandantModule.moduleId == moduleId) {
                return true;
            }
        }
        return false;
    };
    return AuthController;
}(ControllerBase_1.default));
exports.default = AuthController;
