"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Supervisor Klasse, wird für erweiterte Funktionalitäten benötigt
 */
var Supervisor = /** @class */ (function () {
    function Supervisor(user) {
        this.id = user.id;
        this.email = user.email;
        this.firstname = user.firstname;
        this.lastname = user.lastname;
        this.divisionUserId = user.divisionUserId;
        this.divisionCode = user.divisionCode;
        this.divisionDescription = user.divisionDescription;
    }
    Supervisor.prototype.getFullName = function () {
        return this.firstname + " " + this.lastname;
    };
    return Supervisor;
}());
exports.default = Supervisor;
