"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "uk-container uk-container-large uk-margin-top" };
var Translator_1 = require("@/plugins/Translator");
var vue_3 = require("@ionic/vue");
var vue_4 = require("vue");
var zod_1 = require("@vee-validate/zod");
var zod_2 = require("zod");
var vee_validate_1 = require("vee-validate");
var DateFormatter_1 = require("@/plugins/DateFormatter");
var CancelButton_1 = require("@/models/AlertButtons/CancelButton");
var DeleteButton_1 = require("@/models/AlertButtons/DeleteButton");
var QueryKeys_1 = require("@/models/settings/QueryKeys");
var TimeRecordsController_1 = require("@/controllers/modules/zeitwirtschaft/TimeRecordsController");
var vue_query_1 = require("vue-query");
var date_fns_1 = require("date-fns");
var Notifier_1 = require("@/plugins/notifications/Notifier");
var ModuleSyncError_1 = require("@/models/Errors/ModuleSyncError");
var EntitySyncError_1 = require("@/models/Errors/EntitySyncError");
var InputTextarea_vue_1 = require("@/components/common/inputs/InputTextarea.vue");
var FormController_1 = require("@/controllers/FormController");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TimeRecordEditModal',
    props: {
        record: null
    },
    setup: function (__props) {
        var _this = this;
        var props = __props;
        var controller = new TimeRecordsController_1.default();
        var client = (0, vue_query_1.useQueryClient)();
        /*----- texts -----*/
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var saveButtonText = (0, vue_4.computed)(function () { return i18n.t("actions.save"); });
        var deleteButtonText = (0, vue_4.computed)(function () { return i18n.t("actions.delete"); });
        var editRecordSchema = (0, zod_1.toFormValidator)(zod_2.z.object({
            time: zod_2.z.any(),
            mutMessage: zod_2.z.string({ required_error: "Nachricht wird benötigt" }).min(1, "Nachricht wird benötigt").max(150, "Nachricht ist zu lang"),
        }));
        var _a = (0, vee_validate_1.useForm)({ validationSchema: editRecordSchema }), handleSubmit = _a.handleSubmit, errors = _a.errors, resetForm = _a.resetForm;
        resetForm();
        (0, vue_4.onBeforeMount)(function () {
            time.value = null;
            mutMessage.value = "";
            resetForm();
        });
        (0, vue_4.onMounted)(function () {
            console.log("onMounted");
        });
        var cancel = function () {
            resetForm();
            computedMessage.value = "";
            computedTime.value = null;
            vue_3.modalController.dismiss();
        };
        var computedTime = (0, vue_4.computed)({
            get: function () {
                if (!props.record)
                    return null;
                return props.record.time ? DateFormatter_1.default.formatTime(props.record.time) : time.value;
            },
            set: function (newValue) {
                time.value = newValue;
            },
        });
        var computedMessage = (0, vue_4.computed)({
            get: function () {
                var _a;
                if (!props.record)
                    return "";
                return ((_a = props.record) === null || _a === void 0 ? void 0 : _a.mutMessage) ? props.record.mutMessage : mutMessage.value;
            },
            set: function (newValue) {
                mutMessage.value = newValue;
            },
        });
        /*
        TODO: clean up
        const computedTime = computed({
          get() {
            if (!props.record) return null;
            return DateFormatter.formatTime(props.record.time);
          },
          set(newValue) {
            time.value = newValue;
          },
        });
        const computedMessage = computed({
          get() {
            if (!props.record) return "";
            return props.record?.mutMessage ? props.record.mutMessage : "";
          },
          set(newValue) {
            mutMessage.value = newValue;
          },
        });
         */
        var time = (0, vee_validate_1.useField)("time").value;
        var _b = (0, vee_validate_1.useField)("mutMessage"), mutMessage = _b.value, mutMessageMeta = _b.meta;
        function useUpdateTimeRecord() {
            return (0, vue_query_1.useMutation)(function (record) { return controller.updateTimeRecord(record); }, {
                onError: function (error) {
                    if (error instanceof ModuleSyncError_1.default || error instanceof EntitySyncError_1.default) {
                        resetForm();
                    }
                    else {
                        Notifier_1.default.showError({ message: i18n.t("notifications.update.error") });
                    }
                },
                onSuccess: function (data, variables, context) {
                    Notifier_1.default.showSuccess({ message: i18n.t("notifications.update.success") });
                },
                onSettled: function (data, error, variables, context) {
                    console.log("onSettled. - refresh queries");
                    client.refetchQueries([QueryKeys_1.QueryKeys.TIME_RECORDS_HISTORY]);
                    client.refetchQueries([QueryKeys_1.QueryKeys.TIME_RECORDS_CURRENT]);
                },
            });
        }
        var _c = useUpdateTimeRecord(), isLoading = _c.isLoading, isError = _c.isError, error = _c.error, isSuccess = _c.isSuccess, mutate = _c.mutate;
        var onSubmit = handleSubmit(function (values) { return __awaiter(_this, void 0, void 0, function () {
            var message, newTime, date, time, formatTime;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        if (values.time == null)
                            values.time = computedTime.value;
                        if (values.mutMessage == null)
                            values.mutMessage = computedMessage.value;
                        if (props.record == null) {
                            return [2 /*return*/, vue_3.modalController.dismiss()];
                        }
                        message = values.mutMessage;
                        newTime = values.time;
                        date = new Date("1899-12-30T".concat(newTime));
                        time = (0, date_fns_1.format)(date, "HH:mm:ss");
                        formatTime = "1899-12-30T".concat(time);
                        mutate({
                            mandantId: (_a = props.record) === null || _a === void 0 ? void 0 : _a.mandantId,
                            leiUserId: (_b = props.record) === null || _b === void 0 ? void 0 : _b.leiUserId,
                            timeRecordId: (_c = props.record) === null || _c === void 0 ? void 0 : _c.timeRecordId,
                            date: (_d = props.record) === null || _d === void 0 ? void 0 : _d.date,
                            time: formatTime,
                            status: (_e = props.record) === null || _e === void 0 ? void 0 : _e.status,
                            hours: (_f = props.record) === null || _f === void 0 ? void 0 : _f.hours,
                            mutMessage: message,
                            maxId: (_g = props.record) === null || _g === void 0 ? void 0 : _g.maxId,
                            evalId: (_h = props.record) === null || _h === void 0 ? void 0 : _h.evalId,
                            isDeleted: (_j = props.record) === null || _j === void 0 ? void 0 : _j.isDeleted,
                            createdAt: (_k = props.record) === null || _k === void 0 ? void 0 : _k.createdAt,
                            modifiedAt: DateFormatter_1.default.formatDbTimestamp(new Date()),
                        });
                        values.mutMessage = null;
                        computedMessage.value = "";
                        //TODO: add toast
                        return [4 /*yield*/, vue_3.modalController.dismiss()];
                    case 1:
                        //TODO: add toast
                        _l.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        var onDelete = function (record) { return __awaiter(_this, void 0, void 0, function () {
            var alert, role, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("onDelete");
                        return [4 /*yield*/, vue_3.alertController.create({
                                header: i18n.t("alerts.delete.title"),
                                message: i18n.t("alerts.delete.message", { content: DateFormatter_1.default.formatTime(record.time) }),
                                buttons: [CancelButton_1.CancelButton, DeleteButton_1.DeleteButton],
                            })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, alert.onDidDismiss()];
                    case 3:
                        role = (_a.sent()).role;
                        if (!(role == "delete")) return [3 /*break*/, 10];
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, 7, 10]);
                        return [4 /*yield*/, controller.deleteTimeRecord(record)];
                    case 5:
                        response = _a.sent();
                        Notifier_1.default.showSuccess({ message: "".concat(DateFormatter_1.default.formatTime(record.time), " ").concat(i18n.t("notifications.delete.success")) });
                        return [3 /*break*/, 10];
                    case 6:
                        error_1 = _a.sent();
                        if (!(error_1 instanceof ModuleSyncError_1.default)) {
                            Notifier_1.default.showError({ message: "".concat(DateFormatter_1.default.formatTime(record.time), " ").concat(i18n.t("notifications.delete.error")) });
                        }
                        return [3 /*break*/, 10];
                    case 7:
                        vue_3.modalController.dismiss();
                        return [4 /*yield*/, client.refetchQueries([QueryKeys_1.QueryKeys.TIME_RECORDS_HISTORY])];
                    case 8:
                        _a.sent();
                        return [4 /*yield*/, client.refetchQueries([QueryKeys_1.QueryKeys.TIME_RECORDS_CURRENT])];
                    case 9:
                        _a.sent();
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        }); };
        var mutMessageOptions = FormController_1.default.getDefaultTextareaOptions("mutMessage", "Nachricht");
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, [
                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonHeader), null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonToolbar), null, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTitle), null, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(DateFormatter_1.default).formatDate(__props.record.date)), 1)
                                    ]; }),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButtons), { slot: "start" }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonBackButton), {
                                            "default-href": "",
                                            onClick: cancel,
                                            text: ""
                                        })
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), { class: "ion-padding uk-container" }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createElementVNode)("form", {
                            onSubmit: _cache[3] || (_cache[3] =
                                //@ts-ignore
                                function () {
                                    var args = [];
                                    for (var _i = 0; _i < arguments.length; _i++) {
                                        args[_i] = arguments[_i];
                                    }
                                    return ((0, vue_2.unref)(onSubmit) && (0, vue_2.unref)(onSubmit).apply(void 0, args));
                                })
                        }, [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonGrid), null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRow), null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCol), { size: "12" }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonItem), null, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), null, {
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createTextVNode)("Zeit:")
                                                                ]; }),
                                                                _: 1
                                                            }),
                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonInput), {
                                                                id: "time",
                                                                name: "time",
                                                                type: "time",
                                                                modelValue: (0, vue_2.unref)(computedTime),
                                                                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.isRef)(computedTime) ? (computedTime).value = $event : null); })
                                                            }, null, 8, ["modelValue"]),
                                                            (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)((0, vue_2.unref)(errors).time), 1)
                                                        ]; }),
                                                        _: 1
                                                    })
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    }),
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRow), null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCol), { size: "12" }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)(InputTextarea_vue_1.default, {
                                                        modelValue: (0, vue_2.unref)(computedMessage),
                                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((0, vue_2.isRef)(computedMessage) ? (computedMessage).value = $event : null); }),
                                                        meta: (0, vue_2.unref)(mutMessageMeta),
                                                        options: (0, vue_2.unref)(mutMessageOptions),
                                                        error: (0, vue_2.unref)(errors).mutMessage
                                                    }, null, 8, ["modelValue", "meta", "options", "error"])
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    }),
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRow), null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createElementVNode)("div", _hoisted_1, [
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButton), {
                                                    color: 'danger',
                                                    fill: "clear",
                                                    onClick: _cache[2] || (_cache[2] = function ($event) { return (onDelete(__props.record)); }),
                                                    class: "uk-align-left"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(deleteButtonText)), 1)
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButton), {
                                                    color: 'success',
                                                    fill: "solid",
                                                    onClick: (0, vue_2.unref)(onSubmit),
                                                    class: "uk-align-right"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(saveButtonText)), 1)
                                                    ]; }),
                                                    _: 1
                                                }, 8, ["onClick"])
                                            ])
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 1
                            })
                        ], 32)
                    ]; }),
                    _: 1
                })
            ], 64));
        };
    }
});
