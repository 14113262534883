"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "uk-container" };
var _hoisted_2 = { key: 0 };
var _hoisted_3 = {
    key: 1,
    class: "uk-container uk-text-center"
};
var vue_3 = require("@ionic/vue");
var vue_4 = require("vue");
var Translator_1 = require("@/plugins/Translator");
var TodoLogsController_1 = require("@/controllers/modules/aufgabenplanung/TodoLogsController");
var TodoSearch_vue_1 = require("@/components/modules/aufgabenplanung/TodoSearch.vue");
var TodoList_vue_1 = require("@/components/modules/aufgabenplanung/TodoList.vue");
var TodoFilters_vue_1 = require("@/components/modules/aufgabenplanung/TodoFilters.vue");
/*----- texts -----*/
exports.default = (0, vue_1.defineComponent)({
    __name: 'TodoPage',
    setup: function (__props) {
        var _this = this;
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var moduleDescription = (0, vue_4.computed)(function () { return i18n.t("modules.todo.moduleDescription.title"); });
        var controller = new TodoLogsController_1.default();
        var searchField = (0, vue_4.ref)("");
        var filterField = (0, vue_4.ref)("");
        var filterKey = (0, vue_4.ref)(0);
        var searchQuery = (0, vue_4.ref)("");
        var pagination = (0, vue_4.ref)(undefined);
        var isRefreshing = (0, vue_4.ref)(false);
        var _a = controller.useTodoLogsQuery(searchQuery), todoList = _a.data, isLoading = _a.isLoading, fetchNextPage = _a.fetchNextPage, refetch = _a.refetch;
        /**
         * Daten reinitialisieren beim Aufrufen der Seite
         */
        (0, vue_3.onIonViewWillEnter)(function () {
            searchQuery.value = "";
            filterField.value = "";
            filterKey.value++;
            pagination.value = { hasNextPage: true };
        });
        /**
         * Daten anhand der Eingabe suchen
         */
        var search = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, setQuery()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, refetch.value({ refetchPage: function (page, index) { return index == 0; } })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * Daten anhand der Filteroptionen suchen
         */
        var filter = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, setQuery()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, refetch.value({ refetchPage: function (page, index) { return index == 0; } })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * Daten neu laden
         */
        var handleRefresh = function (event) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isRefreshing.value = true;
                        return [4 /*yield*/, setQuery()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, refetch.value({ refetchPage: function (page, index) { return index == 0; } })];
                    case 2:
                        _a.sent();
                        event.target.complete();
                        isRefreshing.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * Suchparameter definieren
         */
        var setQuery = function (query) {
            if (query === void 0) { query = ""; }
            return __awaiter(_this, void 0, void 0, function () {
                var filter_1;
                return __generator(this, function (_a) {
                    searchQuery.value = query;
                    setPagination();
                    /*----- search field -----*/
                    if (searchField.value.length > 0) {
                        if (searchQuery.value.startsWith("?")) {
                            searchQuery.value += "&searchQuery=".concat(searchField.value);
                        }
                        else {
                            searchQuery.value += "?searchQuery=".concat(searchField.value);
                        }
                    }
                    /*----- filters -----*/
                    if (filterField.value.length > 0) {
                        filter_1 = filterField.value.slice(0, -1);
                        if (searchQuery.value.startsWith("?")) {
                            searchQuery.value += "&status=".concat(filter_1);
                        }
                        else {
                            searchQuery.value = "?status=".concat(filter_1);
                        }
                    }
                    return [2 /*return*/];
                });
            });
        };
        /**
         * Seiteninformationen speichern
         */
        var setPagination = function () {
            var _a, _b, _c;
            pagination.value = (_c = (_a = todoList.value) === null || _a === void 0 ? void 0 : _a.pages[((_b = todoList.value) === null || _b === void 0 ? void 0 : _b.pages.length) - 1]) === null || _c === void 0 ? void 0 : _c.pagination;
        };
        /**
         * Falls vorhanden, nächste Seite laden
         */
        var fetchInfiniteTodos = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                    var paginationQuery, index, pageIndex;
                    var _a, _b, _c, _d;
                    return __generator(this, function (_e) {
                        switch (_e.label) {
                            case 0:
                                setPagination();
                                paginationQuery = "";
                                index = ((_a = pagination.value) === null || _a === void 0 ? void 0 : _a.pageIndex) !== undefined ? (_b = pagination.value) === null || _b === void 0 ? void 0 : _b.pageIndex : 1;
                                pageIndex = ((_c = pagination.value) === null || _c === void 0 ? void 0 : _c.hasNextPage) ? "".concat(index + 1) : "".concat(index);
                                paginationQuery = "?pageIndex=".concat(pageIndex, "&pageSize=5");
                                if (!((_d = pagination.value) === null || _d === void 0 ? void 0 : _d.hasNextPage)) return [3 /*break*/, 2];
                                return [4 /*yield*/, setQuery(paginationQuery)];
                            case 1:
                                _e.sent();
                                fetchNextPage.value({ pageParam: searchQuery });
                                _e.label = 2;
                            case 2: return [4 /*yield*/, event.target.complete()];
                            case 3:
                                _e.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, 1000);
                return [2 /*return*/];
            });
        }); };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonPage), null, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonHeader), { translucent: true }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonToolbar), { fixed: false }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTitle), null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(moduleDescription)), 1)
                                        ]; }),
                                        _: 1
                                    }),
                                    (isRefreshing.value)
                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonProgressBar), {
                                            key: 0,
                                            type: "indeterminate"
                                        }))
                                        : (0, vue_2.createCommentVNode)("", true)
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    }),
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), { fullscreen: true }, {
                        default: (0, vue_2.withCtx)(function () {
                            var _a;
                            return [
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonHeader), { collapse: "condense" }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonToolbar), { fixed: false }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTitle), {
                                                    size: "large",
                                                    class: "uk-text-left"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(moduleDescription)), 1)
                                                    ]; }),
                                                    _: 1
                                                })
                                            ]; }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRefresher), {
                                    slot: "fixed",
                                    onIonRefresh: _cache[0] || (_cache[0] = function ($event) { return (handleRefresh($event)); })
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRefresherContent))
                                    ]; }),
                                    _: 1
                                }),
                                (0, vue_2.createElementVNode)("div", _hoisted_1, [
                                    (0, vue_2.createVNode)(TodoSearch_vue_1.default, {
                                        modelValue: searchField.value,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((searchField).value = $event); }),
                                        onSearch: _cache[2] || (_cache[2] = function ($event) { return (search()); })
                                    }, null, 8, ["modelValue"]),
                                    ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(TodoFilters_vue_1.default, {
                                        modelValue: filterField.value,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((filterField).value = $event); }),
                                        onFilter: _cache[4] || (_cache[4] = function ($event) { return (filter()); }),
                                        key: filterKey.value
                                    }, null, 8, ["modelValue"])),
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonGrid), { fixed: true }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            ((0, vue_2.unref)(isLoading))
                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonList), { key: 0 }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(9, function (i) {
                                                            return (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonItem), { key: i }, {
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonThumbnail), { slot: "start" }, {
                                                                        default: (0, vue_2.withCtx)(function () { return [
                                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSkeletonText), { animated: true })
                                                                        ]; }),
                                                                        _: 1
                                                                    }),
                                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), null, {
                                                                        default: (0, vue_2.withCtx)(function () { return [
                                                                            (0, vue_2.createElementVNode)("h3", null, [
                                                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSkeletonText), {
                                                                                    animated: true,
                                                                                    style: { "width": "80%" }
                                                                                })
                                                                            ]),
                                                                            (0, vue_2.createElementVNode)("p", null, [
                                                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSkeletonText), {
                                                                                    animated: true,
                                                                                    style: { "width": "60%" }
                                                                                })
                                                                            ]),
                                                                            (0, vue_2.createElementVNode)("p", null, [
                                                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSkeletonText), {
                                                                                    animated: true,
                                                                                    style: { "width": "30%" }
                                                                                })
                                                                            ])
                                                                        ]; }),
                                                                        _: 1
                                                                    })
                                                                ]; }),
                                                                _: 2
                                                            }, 1024);
                                                        }), 64))
                                                    ]; }),
                                                    _: 1
                                                }))
                                                : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(TodoList_vue_1.default, {
                                                    key: 1,
                                                    "todo-list": (0, vue_2.unref)(todoList)
                                                }, null, 8, ["todo-list"]))
                                        ]; }),
                                        _: 1
                                    })
                                ]),
                                ((_a = pagination.value) === null || _a === void 0 ? void 0 : _a.hasNextPage)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonInfiniteScroll), { onIonInfinite: fetchInfiniteTodos }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonInfiniteScrollContent), { "loading-text": "Weitere ToDos werden geladen" })
                                            ]; }),
                                            _: 1
                                        })
                                    ]))
                                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_3, [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonText), null, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)("Keine weitere ToDos")
                                            ]; }),
                                            _: 1
                                        })
                                    ]))
                            ];
                        }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
