"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuthStore = void 0;
var storage_1 = require("@ionic/storage");
var pinia_1 = require("pinia");
var User_1 = require("@/models/User");
exports.useAuthStore = (0, pinia_1.defineStore)({
    id: "authStore",
    state: function () {
        return ({
            user: null,
            mandanten: null,
            currentMandant: null,
            currentDivision: null,
            /*----- persistent storage -----*/
            storage: new storage_1.Storage({ storeName: "authStore" }),
        });
    },
    getters: {
        activeUser: function (state) { return state.user; },
        activeDivision: function (state) { return state.currentDivision; },
        activeMandant: function (state) { return state.currentMandant; },
    },
    actions: {
        init: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.storage.create()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        setAccessToken: function (accessToken) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.storage.set("access", accessToken)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        getAccessToken: function () {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.storage.get("access")];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        setRefreshToken: function (refreshToken) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.storage.set("refresh", refreshToken)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        getRefreshToken: function () {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.storage.get("refresh")];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        setUser: function (user) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    this.user = user !== null ? new User_1.default(user) : null;
                    return [2 /*return*/];
                });
            });
        },
        getUser: function () {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.user];
                });
            });
        },
        setMandanten: function (mandanten) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var currentMandant, mandant;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            this.mandanten = mandanten;
                            return [4 /*yield*/, this.getCurrentMandant()];
                        case 1:
                            currentMandant = _d.sent();
                            if (!(currentMandant == null)) return [3 /*break*/, 3];
                            this.currentMandant = mandanten[0];
                            return [4 /*yield*/, this.storage.set("currentMandant", mandanten[0])];
                        case 2:
                            _d.sent();
                            return [3 /*break*/, 5];
                        case 3:
                            mandant = mandanten.find(function (mandant) { return mandant.mandantId == currentMandant.mandantId; });
                            if (!(mandant != undefined)) return [3 /*break*/, 5];
                            return [4 /*yield*/, this.setCurrentMandant(mandant)];
                        case 4:
                            _d.sent();
                            this.currentDivision =
                                mandant.mandantUsers !== undefined && ((_a = mandant.mandantUsers[0].divisionUser) === null || _a === void 0 ? void 0 : _a.division) !== undefined
                                    ? (_c = (_b = mandant.mandantUsers[0]) === null || _b === void 0 ? void 0 : _b.divisionUser) === null || _c === void 0 ? void 0 : _c.division
                                    : null;
                            _d.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        getMandanten: function () {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.mandanten];
                });
            });
        },
        setCurrentMandant: function (mandant) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.currentMandant = mandant;
                            return [4 /*yield*/, this.setCurrentDivision()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.storage.set("currentMandant", mandant)];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        getCurrentMandant: function () {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.storage.get("currentMandant")];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        getCurrentMandantUser: function () {
            return __awaiter(this, void 0, Promise, function () {
                var mandant;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.getCurrentMandant()];
                        case 1:
                            mandant = _a.sent();
                            if (!mandant || !mandant.mandantUsers)
                                return [2 /*return*/, null];
                            return [2 /*return*/, mandant.mandantUsers[0]];
                    }
                });
            });
        },
        getCurrentDivisionUser: function () {
            return __awaiter(this, void 0, Promise, function () {
                var mandantUser;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.getCurrentMandantUser()];
                        case 1:
                            mandantUser = _a.sent();
                            if (!mandantUser || !mandantUser.divisionUser)
                                return [2 /*return*/, null];
                            return [2 /*return*/, mandantUser.divisionUser];
                    }
                });
            });
        },
        setCurrentDivision: function () {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_c) {
                    if (this.currentMandant !== null &&
                        this.currentMandant.mandantUsers &&
                        ((_a = this.currentMandant.mandantUsers[0].divisionUser) === null || _a === void 0 ? void 0 : _a.division)) {
                        this.currentDivision = (_b = this.currentMandant.mandantUsers[0].divisionUser) === null || _b === void 0 ? void 0 : _b.division;
                    }
                    else {
                        this.currentDivision = null;
                    }
                    return [2 /*return*/];
                });
            });
        },
        getCurrentDivision: function () {
            return __awaiter(this, void 0, Promise, function () {
                var divisionUser;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.getCurrentDivisionUser()];
                        case 1:
                            divisionUser = _a.sent();
                            if (!divisionUser || !divisionUser.division)
                                return [2 /*return*/, null];
                            return [2 /*return*/, divisionUser.division];
                    }
                });
            });
        },
        ClearStorage: function () {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.storage.clear()];
                        case 1:
                            _a.sent();
                            this.currentDivision = null;
                            this.currentMandant = null;
                            this.mandanten = null;
                            this.user = null;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
