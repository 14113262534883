"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var AuthSlide_vue_1 = require("@/components/slides/AuthSlide.vue");
var WelcomeSlide_vue_1 = require("@/components/slides/WelcomeSlide.vue");
var WelcomeSlides_1 = require("@/stores/slides/WelcomeSlides");
var vue_3 = require("@ionic/vue");
require("swiper/css");
require("swiper/css/pagination");
var vue_4 = require("swiper/vue");
require("@ionic/vue/css/ionic-swiper.css");
var swiper_1 = require("swiper");
var Auth_1 = require("@/stores/Auth");
var router_1 = require("@/router");
var vue_5 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'WelcomePage',
    setup: function (__props) {
        var _this = this;
        var modules = [swiper_1.Autoplay, swiper_1.Keyboard, swiper_1.Pagination, swiper_1.Scrollbar, swiper_1.Zoom, vue_3.IonicSlides];
        var welcomeStore = (0, WelcomeSlides_1.useWelcomeStore)();
        var slides = welcomeStore.slides;
        var authSlide = welcomeStore.authSlide;
        (0, vue_5.onBeforeMount)(function () { return __awaiter(_this, void 0, void 0, function () {
            var authStore, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, (0, Auth_1.useAuthStore)()];
                    case 1:
                        authStore = _d.sent();
                        _b = (_a = console).log;
                        _c = ["welcome access token: "];
                        return [4 /*yield*/, authStore.getAccessToken()];
                    case 2:
                        _b.apply(_a, _c.concat([_d.sent()]));
                        return [4 /*yield*/, authStore.getAccessToken()];
                    case 3:
                        if ((_d.sent()) !== "") {
                            return [2 /*return*/, router_1.default.push({ name: "home" })];
                        }
                        return [2 /*return*/];
                }
            });
        }); });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonPage), { class: "welcomePage" }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_4.Swiper), {
                                modules: modules,
                                pagination: { clickable: true },
                                zoom: true
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(slides), function (slide) {
                                        return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_4.SwiperSlide), {
                                            key: slide.id,
                                            class: "slider"
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(WelcomeSlide_vue_1.default, {
                                                    title: slide.title,
                                                    description: slide.description,
                                                    image: slide.image
                                                }, null, 8, ["title", "description", "image"])
                                            ]; }),
                                            _: 2
                                        }, 1024));
                                    }), 128)),
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_4.SwiperSlide), { class: "slider" }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)(AuthSlide_vue_1.default, {
                                                title: (0, vue_2.unref)(authSlide).title,
                                                image: (0, vue_2.unref)(authSlide).image,
                                                description: (0, vue_2.unref)(authSlide).description
                                            }, null, 8, ["title", "image", "description"])
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
