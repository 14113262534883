"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
/**
 * Globale Config Datei
 */
var Config = /** @class */ (function () {
    function Config() {
    }
    Config.isDevelopment = function () {
        return this.isLocalDevelopment() || this.isServerDevelopment();
    };
    Config.isProduction = function () {
        return process.env.VUE_APP_ENDPOINT_URL === "production";
    };
    Config.isLocalDevelopment = function () {
        return process.env.VUE_APP_ENDPOINT_URL === "development";
    };
    Config.isServerDevelopment = function () {
        return process.env.VUE_APP_ENDPOINT_URL === "developmentServer";
    };
    /*
     * returns the api endpoint based on the environment
     */
    Config.getApiEndpoint = function () {
        var endpoint = process.env.VUE_APP_ENDPOINT_URL;
        console.log("ApiEndpoint: ", endpoint);
        if (endpoint === undefined)
            return "";
        return endpoint;
    };
    Config.ApiEndpoints = {
        development: "https://localhost:7094/api/",
        developmentServer: "https://dev.maxweb.paus.ch:7094/api/",
        production: "https://maxweb.paus.ch:5000/api",
    };
    return Config;
}());
exports.Config = Config;
