"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var ControllerBase_1 = require("@/controllers/ControllerBase");
var Auth_1 = require("@/stores/Auth");
var Theme_1 = require("@/stores/Theme");
var Language_1 = require("@/stores/Language");
/**
 * Controller für den WebUser
 *
 * @see MaxWebBackend UsersController
 */
var UsersController = /** @class */ (function (_super) {
    __extends(UsersController, _super);
    function UsersController() {
        return _super.call(this) || this;
    }
    /**
     * Gibt den aktuellsten LeiJournal Eintrag mit dem BookingType 99 zurück
     *
     * @remarks GET: api/users/today/mandanten/mandantId/leiusers/leiUserId/saldo
     */
    UsersController.prototype.getTodaySaldo = function () {
        return __awaiter(this, void 0, Promise, function () {
            var mandant, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentMandant()];
                    case 1:
                        mandant = _e.sent();
                        if (mandant == null || mandant.mandantUsers == undefined)
                            return [2 /*return*/, null];
                        _b = (_a = this.api.httpClient).get;
                        _c = ["users/today/mandanten/".concat(mandant === null || mandant === void 0 ? void 0 : mandant.mandantId, "/leiUsers/").concat(mandant.mandantUsers[0].leiUserId, "/saldo")];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 3:
                        response = _e.sent();
                        console.log("getTodaySaldo", response.data);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Gibt alle WebUser Einstellungen zurück
     *
     * @remarks GET: api/users/me/settings
     */
    UsersController.prototype.getSettings = function () {
        return __awaiter(this, void 0, Promise, function () {
            var response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _b = (_a = this.api.httpClient).get;
                        _c = ["users/me/settings"];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 2:
                        response = _e.sent();
                        if (typeof response.data === "string")
                            return [2 /*return*/, null];
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Globale Einstellungen speichern <br/>
     * Dabei können nur folgende Properties geändert werden: <b> Theme </b> <b> Language </b> <b> MandantId </b> <br/>
     * Die restlichen werden im Backend ignoriert
     *
     * @remarks POST: api/users/me/settings/global
     */
    UsersController.prototype.postGlobalSettings = function (settings) {
        return __awaiter(this, void 0, Promise, function () {
            var response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _b = (_a = this.api.httpClient).post;
                        _c = ["users/me/settings/global", settings];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 2:
                        response = _e.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Benutzereinstellungen laden und in localStorage speichern <br/>
     * Falls keine Einstellungen für den User existieren, werden die aktuellen Werte
     * in die Datenbank gespeichert
     *
     * @see getSettings()
     * @see saveSettings()
     */
    UsersController.prototype.loadSettings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, authStore, themeStore, languageStore, user, mandant, theme, language, settings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSettings()];
                    case 1:
                        response = _a.sent();
                        authStore = (0, Auth_1.useAuthStore)();
                        themeStore = (0, Theme_1.useThemeStore)();
                        languageStore = (0, Language_1.useLanguageStore)();
                        if (!(response == null)) return [3 /*break*/, 6];
                        return [4 /*yield*/, authStore.getUser()];
                    case 2:
                        user = _a.sent();
                        return [4 /*yield*/, authStore.getCurrentMandant()];
                    case 3:
                        mandant = _a.sent();
                        if ((user === null || user === void 0 ? void 0 : user.id) == undefined || (mandant === null || mandant === void 0 ? void 0 : mandant.mandantId) == undefined)
                            return [2 /*return*/, false];
                        return [4 /*yield*/, themeStore.getTheme()];
                    case 4:
                        theme = _a.sent();
                        language = languageStore.getLanguage();
                        settings = {
                            userId: user === null || user === void 0 ? void 0 : user.id,
                            theme: theme,
                            language: language,
                            mandantId: mandant.mandantId,
                        };
                        return [4 /*yield*/, this.saveSettings(settings)];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                    case 6:
                        console.log("response settings", response);
                        if (!response.mandant) return [3 /*break*/, 8];
                        return [4 /*yield*/, authStore.setCurrentMandant(response.mandant)];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8:
                        if (!response.theme) return [3 /*break*/, 10];
                        return [4 /*yield*/, themeStore.setTheme(response.theme)];
                    case 9:
                        _a.sent();
                        _a.label = 10;
                    case 10:
                        if (!response.language) return [3 /*break*/, 12];
                        return [4 /*yield*/, languageStore.setLanguage(response.language)];
                    case 11:
                        _a.sent();
                        _a.label = 12;
                    case 12: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Benutzereinstellungen speichern
     *
     * @see postGlobalSettings()
     */
    UsersController.prototype.saveSettings = function (settings) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.postGlobalSettings(settings)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return UsersController;
}(ControllerBase_1.default));
exports.default = UsersController;
