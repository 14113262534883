"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("@ionic/vue");
var Theme_1 = require("@/stores/Theme");
var Translator_1 = require("@/plugins/Translator");
var vue_4 = require("vue");
var Themes_1 = require("@/models/settings/Themes");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ThemeSwitcher',
    emits: ["updateSettings"],
    setup: function (__props, _a) {
        var emit = _a.emit;
        return __awaiter(this, void 0, void 0, function () {
            var __temp, __restore, themeStore, theme, translator, i18n, okText, cancelText, changeTheme;
            var _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        themeStore = (0, Theme_1.useThemeStore)();
                        ;
                        (_b = (0, vue_1.withAsyncContext)(function () { return themeStore.init(); }), __temp = _b[0], __restore = _b[1]);
                        return [4 /*yield*/, __temp];
                    case 1:
                        (_c.sent(),
                            __restore());
                        theme = (0, vue_4.ref)(themeStore.theme);
                        translator = Translator_1.default.getInstance();
                        i18n = translator.getI18nGlobalInstance();
                        okText = (0, vue_4.computed)(function () { return i18n.t("actions.save"); });
                        cancelText = (0, vue_4.computed)(function () { return i18n.t("actions.cancel"); });
                        changeTheme = function (theme) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        emit("updateSettings", { theme: theme });
                                        return [4 /*yield*/, themeStore.setTheme(theme)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); };
                        return [2 /*return*/, function (_ctx, _cache) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonItem), null, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), null, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("settings.theme.title")), 1)
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSelect), {
                                            placeholder: _ctx.$t('settings.theme.placeholder'),
                                            modelValue: theme.value,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((theme).value = $event); }),
                                            "ok-text": (0, vue_2.unref)(okText),
                                            "cancel-text": (0, vue_2.unref)(cancelText),
                                            onIonChange: _cache[1] || (_cache[1] = function ($event) { return (changeTheme($event.detail.value)); })
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSelectOption), {
                                                    value: (0, vue_2.unref)(Themes_1.default).DARK
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("settings.theme.themes.dark")), 1)
                                                    ]; }),
                                                    _: 1
                                                }, 8, ["value"]),
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSelectOption), {
                                                    value: (0, vue_2.unref)(Themes_1.default).LIGHT
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("settings.theme.themes.light")), 1)
                                                    ]; }),
                                                    _: 1
                                                }, 8, ["value"])
                                            ]; }),
                                            _: 1
                                        }, 8, ["placeholder", "modelValue", "ok-text", "cancel-text"])
                                    ]; }),
                                    _: 1
                                }));
                            }];
                }
            });
        });
    }
});
