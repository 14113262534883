"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var date_fns_1 = require("date-fns");
var Language_1 = require("@/stores/Language");
var DateFormatter = /** @class */ (function () {
    function DateFormatter() {
    }
    DateFormatter.formatMonth = function (date) {
        return DateFormatter.format(date, DateFormatter.monthYearFormat);
    };
    DateFormatter.formatDate = function (date) {
        if (date == null)
            return null;
        var dateFormat = "EEEE dd.MM.yyyy";
        return DateFormatter.format(date, dateFormat);
    };
    DateFormatter.formatDateShort = function (date) {
        if (date == null)
            return null;
        var dateFormat = "dd.MM.yyyy";
        return DateFormatter.format(date, dateFormat);
    };
    DateFormatter.formatTime = function (time) {
        if (time == null)
            return null;
        return (0, date_fns_1.format)((0, date_fns_1.parseISO)(time), "HH:mm");
    };
    /**
     * Datum / Zeit im Format EEEE dd.MM.yyyy HH:mm:ss
     */
    DateFormatter.formatDateTime = function (dateTime) {
        if (dateTime == null)
            return null;
        var dateFormat = "EEEE dd.MM.yyyy HH:mm:ss";
        return DateFormatter.format(dateTime, dateFormat);
    };
    /**
     * Datum / Zeit im Format EEEE dd.MM.yyyy HH:mm
     */
    DateFormatter.formatDateTimeShort = function (dateTime) {
        if (dateTime == null)
            return null;
        var dateFormat = "EEEE dd.MM.yyyy HH:mm";
        return DateFormatter.format(dateTime, dateFormat);
    };
    DateFormatter.formatDbMonth = function (date) {
        return DateFormatter.format(date, DateFormatter.yearMonthDbFormat);
    };
    DateFormatter.formatDbDay = function (date) {
        return DateFormatter.format(date, DateFormatter.yearMonthDayDbFormat);
    };
    DateFormatter.formatDbTimestamp = function (date) {
        var formatDate = (0, date_fns_1.format)(date, "yyyy-MM-dd");
        var formatTime = (0, date_fns_1.format)(date, "HH:mm:ss");
        return "".concat(formatDate, "T").concat(formatTime);
    };
    DateFormatter.format = function (date, dateFormat) {
        var languageStore = (0, Language_1.useLanguageStore)();
        var locale = languageStore.getLocale();
        if (typeof date == "string") {
            return (0, date_fns_1.format)((0, date_fns_1.parseISO)(date), dateFormat, { locale: locale });
        }
        else if (date instanceof Date) {
            return (0, date_fns_1.format)(date, dateFormat, { locale: locale });
        }
    };
    DateFormatter.yearMonthDayDbFormat = "yyyy-MM-dd";
    DateFormatter.yearMonthDbFormat = "yyyy-MM";
    DateFormatter.monthYearFormat = "MMMM yyyy";
    return DateFormatter;
}());
exports.default = DateFormatter;
