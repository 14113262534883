"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var router_1 = require("@/router");
var vue_3 = require("@ionic/vue");
var Auth_1 = require("@/stores/Auth");
var zod_1 = require("@vee-validate/zod");
var zod_2 = require("zod");
var vee_validate_1 = require("vee-validate");
var AuthController_1 = require("@/controllers/AuthController");
var vue_4 = require("vue");
var Notifier_1 = require("@/plugins/notifications/Notifier");
var Translator_1 = require("@/plugins/Translator");
var UsersController_1 = require("@/controllers/UsersController");
/*----- internationalization -----*/
exports.default = (0, vue_1.defineComponent)({
    __name: 'LoginModal',
    setup: function (__props) {
        var _this = this;
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var closeText = (0, vue_4.computed)(function () { return i18n.t("actions.exit"); });
        var authStore = (0, Auth_1.useAuthStore)();
        var loginSchema = (0, zod_1.toFormValidator)(zod_2.z.object({
            email: zod_2.z.string({ required_error: "Email benötigt" }).min(1, "Email benötigt").email("Email ungültig").trim(),
            password: zod_2.z.string({ required_error: "Passwort benötigt" }).min(1, "Passwort benötigt").trim(),
        }));
        //
        var _a = (0, vee_validate_1.useForm)({ validationSchema: loginSchema }), handleSubmit = _a.handleSubmit, errors = _a.errors, resetForm = _a.resetForm;
        var email = (0, vee_validate_1.useField)("email").value;
        var password = (0, vee_validate_1.useField)("password").value;
        var controller = new AuthController_1.default();
        var onSubmit = handleSubmit(function (values) { return __awaiter(_this, void 0, void 0, function () {
            var data, user, mandanten, usersController, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 10, , 11]);
                        return [4 /*yield*/, controller.login({ email: values.email, password: values.password })];
                    case 1:
                        data = _a.sent();
                        return [4 /*yield*/, authStore.setAccessToken(data.accessToken)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, authStore.setRefreshToken(data.refreshToken)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, controller.getMe()];
                    case 4:
                        user = _a.sent();
                        return [4 /*yield*/, controller.getMandanten()];
                    case 5:
                        mandanten = _a.sent();
                        return [4 /*yield*/, authStore.setUser(user)];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, authStore.setMandanten(mandanten)];
                    case 7:
                        _a.sent();
                        usersController = new UsersController_1.default();
                        return [4 /*yield*/, usersController.loadSettings()];
                    case 8:
                        response = _a.sent();
                        return [4 /*yield*/, vue_3.modalController.dismiss()];
                    case 9:
                        _a.sent();
                        resetForm();
                        return [2 /*return*/, router_1.default.push({ name: "home" })];
                    case 10:
                        error_1 = _a.sent();
                        if (error_1.code === "ERR_NETWORK") {
                            return [2 /*return*/, Notifier_1.default.showError({ message: "Verbindung zum Server fehlgeschlagen" })];
                        }
                        if (error_1.response.data.message == "not logged in") {
                            return [2 /*return*/, Notifier_1.default.showError({ message: "Email oder Passwort falsch" })];
                        }
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/, resetForm()];
                }
            });
        }); });
        var cancel = function () {
            vue_3.modalController.dismiss();
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonModal), { trigger: "open-login-modal" }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonHeader), null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonToolbar), null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButtons), { slot: "start" }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButton), {
                                                onClick: _cache[0] || (_cache[0] = function ($event) { return (cancel()); })
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(closeText)), 1)
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    }),
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTitle), null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createTextVNode)("Max® CRM/ERP Software")
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    }),
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), { class: "ion-padding" }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createElementVNode)("form", {
                                onSubmit: _cache[3] || (_cache[3] =
                                    //@ts-ignore
                                    function () {
                                        var args = [];
                                        for (var _i = 0; _i < arguments.length; _i++) {
                                            args[_i] = arguments[_i];
                                        }
                                        return ((0, vue_2.unref)(onSubmit) && (0, vue_2.unref)(onSubmit).apply(void 0, args));
                                    })
                            }, [
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonItem), null, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), { position: "stacked" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)("Email")
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonInput), {
                                            ref: "input",
                                            type: "email",
                                            name: "email",
                                            modelValue: (0, vue_2.unref)(email),
                                            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((0, vue_2.isRef)(email) ? (email).value = $event : null); }),
                                            id: "email",
                                            placeholder: "max@paus.ch"
                                        }, null, 8, ["modelValue"]),
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonText), { color: "danger" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(errors).email), 1)
                                            ]; }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonItem), { class: "uk-margin-top" }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), { position: "stacked" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)("Passwort")
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonInput), {
                                            ref: "input",
                                            type: "password",
                                            modelValue: (0, vue_2.unref)(password),
                                            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((0, vue_2.isRef)(password) ? (password).value = $event : null); }),
                                            id: "password",
                                            placeholder: "****"
                                        }, null, 8, ["modelValue"]),
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonText), { color: "danger" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(errors).password), 1)
                                            ]; }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButton), {
                                    class: "uk-align-center",
                                    strong: true,
                                    type: "submit"
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createTextVNode)("anmelden")
                                    ]; }),
                                    _: 1
                                })
                            ], 32)
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
