"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("@ionic/vue");
var Translator_1 = require("@/plugins/Translator");
var vue_4 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BackButton',
    props: {
        defaultHref: null,
        disableText: { type: Boolean }
    },
    setup: function (__props) {
        var props = __props;
        /*----- texts -----*/
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var backButtonText = !props.disableText ? (0, vue_4.computed)(function () { return i18n.t("actions.back"); }) : "";
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonBackButton), {
                "default-href": __props.defaultHref,
                text: (0, vue_2.unref)(backButtonText)
            }, null, 8, ["default-href", "text"]));
        };
    }
});
