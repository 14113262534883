"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var Translator_1 = require("@/plugins/Translator");
var vue_3 = require("@ionic/vue");
var vue_4 = require("vue");
var icons_1 = require("ionicons/icons");
var MaxWeb_1 = require("@/stores/MaxWeb");
/*----- texts -----*/
exports.default = (0, vue_1.defineComponent)({
    __name: 'AppUpdater',
    emits: ["update"],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var updateText = (0, vue_4.computed)(function () { return i18n.t("actions.update"); });
        var maxWebStore = (0, MaxWeb_1.useMaxWebStore)();
        var update = function () {
            emit("update");
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonItem), null, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createTextVNode)("Version " + (0, vue_2.toDisplayString)((0, vue_2.unref)(maxWebStore).appVersion), 1)
                        ]; }),
                        _: 1
                    }),
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButton), {
                        onClick: _cache[0] || (_cache[0] = function ($event) { return (update()); }),
                        color: "success",
                        slot: "end"
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(updateText)) + " ", 1),
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonIcon), {
                                icon: (0, vue_2.unref)(icons_1.syncOutline),
                                slot: "start"
                            }, null, 8, ["icon"])
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
