"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ControllerBase_1 = require("@/controllers/ControllerBase");
/**
 * Controller für Form / Input Felder
 *
 */
var FormController = /** @class */ (function (_super) {
    __extends(FormController, _super);
    function FormController() {
        return _super.call(this) || this;
    }
    FormController.getDefaultTextareaOptions = function (name, label) {
        var options = {
            name: name,
            label: label,
            autoGrow: true,
            counter: true,
            validation: { maxLength: 250 },
        };
        return options;
    };
    FormController.getDefaultToggleOptions = function (name, label) {
        var options = {
            name: name,
            label: label,
        };
        return options;
    };
    FormController.getDefaultNumberOptions = function (name, label) {
        var options = {
            name: name,
            label: label,
        };
        return options;
    };
    FormController.getDefaultDateTimeOptions = function (name, label, presentation) {
        if (presentation === void 0) { presentation = "date-time"; }
        var options = {
            name: name,
            label: label,
            presentation: presentation,
        };
        return options;
    };
    return FormController;
}(ControllerBase_1.default));
exports.default = FormController;
