"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var Translator_1 = require("@/plugins/Translator");
var vue_3 = require("@ionic/vue");
var vue_4 = require("vue");
var AbsencesController_1 = require("@/controllers/modules/zeitwirtschaft/AbsencesController");
var vee_validate_1 = require("vee-validate");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AbsenceTypeSelect',
    props: {
        modelValue: null,
        error: null,
        meta: null
    },
    emits: ["update:modelValue"],
    setup: function (__props, _a) {
        var emit = _a.emit;
        return __awaiter(this, void 0, void 0, function () {
            var __temp, __restore, props, translator, i18n, typeText, selectText, okText, cancelText, controller, absenceTypes, selected, selectType;
            var _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        props = __props;
                        translator = Translator_1.default.getInstance();
                        i18n = translator.getI18nGlobalInstance();
                        typeText = (0, vue_4.computed)(function () { return i18n.t("modules.zew.absence.type"); });
                        selectText = (0, vue_4.computed)(function () { return i18n.t("actions.select"); });
                        okText = (0, vue_4.computed)(function () { return i18n.t("actions.save"); });
                        cancelText = (0, vue_4.computed)(function () { return i18n.t("actions.cancel"); });
                        controller = new AbsencesController_1.default();
                        (_b = (0, vue_1.withAsyncContext)(function () { return controller.getAbsenceTypes(); }), __temp = _b[0], __restore = _b[1]);
                        return [4 /*yield*/, __temp];
                    case 1:
                        absenceTypes = (__temp = _c.sent(),
                            __restore(),
                            __temp);
                        selectType = function (type) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                emit('update:modelValue', type.absenceTypeId);
                                selected = type;
                                return [2 /*return*/];
                            });
                        }); };
                        return [2 /*return*/, function (_ctx, _cache) {
                                var _directive_validation_class_names = (0, vue_2.resolveDirective)("validation-class-names");
                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vee_validate_1.Field), { name: "type" }, {
                                    default: (0, vue_2.withCtx)(function (_a) {
                                        var meta = _a.meta;
                                        return [
                                            (0, vue_2.withDirectives)(((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonItem), null, {
                                                default: (0, vue_2.withCtx)(function () {
                                                    var _a;
                                                    return [
                                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), null, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(typeText)), 1)
                                                            ]; }),
                                                            _: 1
                                                        }),
                                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSelect), {
                                                            id: "type",
                                                            name: "type",
                                                            placeholder: (0, vue_2.unref)(selectText),
                                                            "ok-text": (0, vue_2.unref)(okText),
                                                            "cancel-text": (0, vue_2.unref)(cancelText),
                                                            "selected-text": (_a = (0, vue_2.unref)(selected)) === null || _a === void 0 ? void 0 : _a.description,
                                                            onIonChange: _cache[0] || (_cache[0] = function ($event) { return (selectType((0, vue_4.toRaw)($event.detail.value))); })
                                                        }, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(absenceTypes), function (absenceType) {
                                                                    return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonSelectOption), {
                                                                        key: absenceType.absenceTypeId,
                                                                        value: absenceType
                                                                    }, {
                                                                        default: (0, vue_2.withCtx)(function () { return [
                                                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(absenceType.description), 1)
                                                                        ]; }),
                                                                        _: 2
                                                                    }, 1032, ["value"]));
                                                                }), 128))
                                                            ]; }),
                                                            _: 1
                                                        }, 8, ["placeholder", "ok-text", "cancel-text", "selected-text"]),
                                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonNote), { slot: "error" }, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.error), 1)
                                                            ]; }),
                                                            _: 1
                                                        })
                                                    ];
                                                }),
                                                _: 2
                                            }, 1024)), [
                                                [_directive_validation_class_names, meta]
                                            ])
                                        ];
                                    }),
                                    _: 1
                                }));
                            }];
                }
            });
        });
    }
});
