"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-5e0976be"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "space-height" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'SectionSpacer',
    props: {
        height: null
    },
    setup: function (__props) {
        var props = __props;
        (0, vue_1.useCssVars)(function (_ctx) { return ({
            "6dfe5a18": (__props.height)
        }); });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1));
        };
    }
});
