"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var ControllerBase_1 = require("@/controllers/ControllerBase");
var Util_1 = require("@/plugins/Util");
var date_fns_1 = require("date-fns");
var vue_query_1 = require("vue-query");
var QueryKeys_1 = require("@/models/settings/QueryKeys");
var ModuleList_1 = require("@/models/settings/ModuleList");
var EntitySyncError_1 = require("@/models/Errors/EntitySyncError");
var DateFormatter_1 = require("@/plugins/DateFormatter");
/**
 * Controller für die TimeRecords.
 *
 * @see MaxWebBackend TimeRecordsController
 */
var TimeRecordsController = /** @class */ (function (_super) {
    __extends(TimeRecordsController, _super);
    function TimeRecordsController() {
        var _this = _super.call(this) || this;
        _this.moduleRoute = "modules/zeitwirtschaft/";
        _this.moduleId = ModuleList_1.ModuleList.TIME_MANAGEMENT;
        return _this;
    }
    /**
     * Gibt alle Records die nicht gelöscht oder älter als 8 Tage sind zurück
     *
     * @remarks GET: api/modules/zeitwirtschaft/mandanten/mandantId/leiusers/leiUserId/timerecords
     * @returns Record Array
     */
    TimeRecordsController.prototype.getTimeRecords = function () {
        return __awaiter(this, void 0, Promise, function () {
            var user, mandant, mandantUser, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        console.log("getRecord");
                        return [4 /*yield*/, this.authStore.getUser()];
                    case 1:
                        user = _e.sent();
                        return [4 /*yield*/, this.authStore.getCurrentMandant()];
                    case 2:
                        mandant = _e.sent();
                        console.log("current mandant: ", mandant);
                        if (!(mandant === null || mandant === void 0 ? void 0 : mandant.mandantUsers))
                            return [2 /*return*/, null];
                        mandantUser = mandant === null || mandant === void 0 ? void 0 : mandant.mandantUsers[0];
                        console.log("user", user);
                        console.log("mandant", mandant);
                        _b = (_a = this.api.httpClient).get;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(mandant.mandantId, "/leiusers/").concat(mandantUser === null || mandantUser === void 0 ? void 0 : mandantUser.leiUserId, "/timerecords")];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 3: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 4:
                        response = _e.sent();
                        console.log("timeRecords: ", response);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Gibt neuester, nicht als gelöscht markierter TimeRecord zurück
     *
     * @remarks GET: api/modules/zeitwirtschaft/mandanten/mandantId/leiusers/leiUserId/timerecords/current
     * @returns Neuester Record
     */
    TimeRecordsController.prototype.getActiveTimeRecord = function () {
        return __awaiter(this, void 0, Promise, function () {
            var mandant, mandantUser, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentMandant()];
                    case 1:
                        mandant = _e.sent();
                        if (!(mandant === null || mandant === void 0 ? void 0 : mandant.mandantUsers))
                            return [2 /*return*/, null];
                        mandantUser = mandant === null || mandant === void 0 ? void 0 : mandant.mandantUsers[0];
                        _b = (_a = this.api.httpClient).get;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(mandant.mandantId, "/leiusers/").concat(mandantUser === null || mandantUser === void 0 ? void 0 : mandantUser.leiUserId, "/timerecords/current")];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(), _d)]))];
                    case 3:
                        response = _e.sent();
                        console.log("activeTimeRecord: ", response.data);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * TimeRecord hinzufügen
     *
     * @remarks POST: api/modules/zeitwirtschaft/mandanten/mandantId/leiusers/leiUserId/timerecords
     * @returns ITimeRecord oder StatusCode bei Fehler
     */
    TimeRecordsController.prototype.postTimeRecord = function (timeRecord) {
        return __awaiter(this, void 0, void 0, function () {
            var mandant, mandantUser, date, dateNow, timeNow, hoursNow, minutesNow, formatDateNow, formatTimeNow, timestamp, record, timePrev, hoursPrev, minutesPrev, hours, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentMandant()];
                    case 1:
                        mandant = _e.sent();
                        if (!(mandant === null || mandant === void 0 ? void 0 : mandant.mandantUsers))
                            return [2 /*return*/, null];
                        mandantUser = mandant === null || mandant === void 0 ? void 0 : mandant.mandantUsers[0];
                        date = new Date();
                        console.log("before post: date ", date);
                        console.log("before post: ", timeRecord);
                        dateNow = (0, date_fns_1.format)(date, "yyyy-MM-dd");
                        timeNow = (0, date_fns_1.format)(date, "HH:mm:ss");
                        hoursNow = parseInt((0, date_fns_1.format)(date, "HH"));
                        minutesNow = parseInt((0, date_fns_1.format)(date, "mm"));
                        formatDateNow = "".concat(dateNow, "T00:00:00");
                        formatTimeNow = "1899-12-30T".concat(timeNow);
                        timestamp = DateFormatter_1.default.formatDbTimestamp(date);
                        record = {
                            status: timeRecord.status,
                            leiUserId: timeRecord.leiUserId,
                            mandantId: timeRecord.mandantId,
                            time: formatTimeNow,
                            date: formatDateNow,
                            isDeleted: false,
                            createdAt: timestamp,
                            modifiedAt: timestamp,
                        };
                        console.log("record: ", record);
                        if (record.status === 1) {
                            timePrev = new Date(timeRecord.time);
                            hoursPrev = parseInt((0, date_fns_1.format)(timePrev, "HH"));
                            minutesPrev = parseInt((0, date_fns_1.format)(timePrev, "mm"));
                            hours = hoursNow + minutesNow / 60 - (hoursPrev + minutesPrev / 60);
                            console.log("hours: ", hours);
                            record.status = 0;
                            record.hours = hours;
                        }
                        else {
                            record.status = 1;
                        }
                        console.log("post timeRecord", record);
                        _b = (_a = this.api.httpClient).post;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(mandant.mandantId, "/leiusers/").concat(mandantUser === null || mandantUser === void 0 ? void 0 : mandantUser.leiUserId, "/timerecords"), record];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(), _d)]))];
                    case 3:
                        response = _e.sent();
                        console.log("post response", response);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * TimeRecord aktualisieren <br/>
     * dabei wird überprüft, ob das Modul gerade synchronisiert wird oder der Record noch nicht synchronisiert wurde
     *
     * @see checkModuleStatus()
     * @see checkTimeRecordStatus()
     * @remarks PUT: api/modules/zeitwirtschaft/mandanten/mandantId/timerecords/timeRecordId
     * @returns StatusCode
     */
    TimeRecordsController.prototype.updateTimeRecord = function (timeRecord) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.checkModuleStatus(timeRecord.mandantId, this.moduleId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.checkTimeRecordStatus(timeRecord)];
                    case 2:
                        _a.sent();
                        console.log("update timeRecord", timeRecord);
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 3:
                        headers = _a.sent();
                        return [4 /*yield*/, this.api.httpClient.put("".concat(this.moduleRoute, "mandanten/").concat(timeRecord.mandantId, "/timerecords/").concat(timeRecord.timeRecordId), timeRecord, { headers: headers })];
                    case 4:
                        response = _a.sent();
                        console.log("update timeRecord", response);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * TimeRecord als gelöscht markieren <br/>
     * dabei wird <b> IsDeleted </b> auf <b>true</b> gesetzt
     *
     * @remarks DELETE: api/modules/zeitwirtschaft/mandanten/mandantId/timerecords/timeRecordId
     * @returns StatusCode
     */
    TimeRecordsController.prototype.deleteTimeRecord = function (timeRecord) {
        return __awaiter(this, void 0, void 0, function () {
            var mandant, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.checkModuleStatus(timeRecord.mandantId, this.moduleId)];
                    case 1:
                        _e.sent();
                        return [4 /*yield*/, this.authStore.getCurrentMandant()];
                    case 2:
                        mandant = _e.sent();
                        if (!mandant || timeRecord.timeRecordId == null)
                            return [2 /*return*/, null];
                        console.log("delete timeRecord", timeRecord);
                        _b = (_a = this.api.httpClient).delete;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(mandant.mandantId, "/timerecords/").concat(timeRecord.timeRecordId)];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 3: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(), _d)]))];
                    case 4:
                        response = _e.sent();
                        console.log("delete response", response);
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * TimeRecords nach Tag gruppieren und innerhalb nach Zeit sortieren
     *
     *
     * @returns Records Liste nach Tag gruppiert
     */
    TimeRecordsController.formatRecords = function (records) {
        var days = (0, Util_1.objectGroupBy)(records, "date");
        Object.values(days).forEach(function (day) {
            return day.sort(function (first, second) { return first.time < second.time; });
        });
        return days;
    };
    /**
     * TimeRecord Stunde leserlich formatieren
     *
     * @returns Formatierte Stunde
     */
    TimeRecordsController.formatRecordHours = function (record) {
        if (record.hours === undefined)
            return "";
        var date = new Date(0, 0);
        date.setMinutes(record.hours * 60);
        return "".concat(Math.trunc(record.hours * 100) / 100, " (").concat(date.toTimeString().slice(0, 5), ")");
    };
    /**
     * Überprüfen ob der TimeRecord aktiv ist "kommen"
     *
     */
    TimeRecordsController.isActive = function (record) {
        if (record === null || record === undefined)
            return false;
        return record.status === 1;
    };
    /**
     * Überprüfen ob der TimeRecord von heute ist
     *
     */
    TimeRecordsController.isRecordToday = function (record) {
        var date = new Date();
        var recordDate = new Date(record.date);
        if (recordDate == null)
            return false;
        return date.toDateString() === recordDate.toDateString();
    };
    /**
     * Überprüfen ob der TimeRecord bereits synchronisiert wurde
     *
     * @returns EntitySyncError falls der TimeRecord noch nicht synchronisiert wurde
     */
    TimeRecordsController.prototype.checkTimeRecordStatus = function (timeRecord) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                if (timeRecord.maxId == undefined) {
                    this.showEntitySyncError();
                    throw new EntitySyncError_1.default("");
                }
                return [2 /*return*/];
            });
        });
    };
    /*----- queries -----*/
    /**
     * Gibt den Query für die Time Records History zurück
     *
     * @see QueryKeys
     */
    TimeRecordsController.prototype.useHistoryQuery = function () {
        var _this = this;
        return (0, vue_query_1.useQuery)([QueryKeys_1.QueryKeys.TIME_RECORDS_HISTORY], function () { return _this.getTimeRecords(); }, {
            select: function (data) { return data; },
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
            refetchOnMount: true,
        });
    };
    /**
     * Gibt den Query für den aktuellen TimeRecord zurück
     *
     * @see QueryKeys
     */
    TimeRecordsController.prototype.useCurrentQuery = function () {
        var _this = this;
        return (0, vue_query_1.useQuery)([QueryKeys_1.QueryKeys.TIME_RECORDS_CURRENT], function () { return _this.getActiveTimeRecord(); }, {
            select: function (data) { return data; },
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
            refetchOnMount: true,
        });
    };
    return TimeRecordsController;
}(ControllerBase_1.default));
exports.default = TimeRecordsController;
