"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var App_vue_1 = require("./App.vue");
var router_1 = require("./router");
var vue_2 = require("@ionic/vue");
var pinia_1 = require("pinia");
var vue_query_1 = require("vue-query");
require("@/plugins/ServiceWorker/registerServiceWorker");
/*----- Core CSS required for Ionic components to work properly -----*/
require("@ionic/vue/css/core.css");
/*----- Basic CSS for apps built with Ionic -----*/
require("@ionic/vue/css/normalize.css");
require("@ionic/vue/css/structure.css");
require("@ionic/vue/css/typography.css");
/*----- Additional Ionic CSS utils -----*/
require("@ionic/vue/css/padding.css");
require("@ionic/vue/css/float-elements.css");
require("@ionic/vue/css/text-alignment.css");
require("@ionic/vue/css/text-transformation.css");
require("@ionic/vue/css/flex-utils.css");
require("@ionic/vue/css/display.css");
/*----- Theme variables -----*/
require("./themes/variables.scss");
/*----- custom css -----*/
require("./themes/app.scss");
/*----- localization -----*/
var Translator_1 = require("@/plugins/Translator");
/*----- internationalization -----*/
var i18n = Translator_1.default.getInstance().getI18nInstance();
/*----- pinia store -----*/
var pinia = (0, pinia_1.createPinia)();
/*----- create vue app -----*/
var app = (0, vue_1.createApp)(App_vue_1.default).use(vue_2.IonicVue).use(router_1.default).use(pinia).use(vue_query_1.VueQueryPlugin).use(i18n);
/*----- validation directive, add or remove css class -----*/
app.directive("validation-class-names", {
    updated: function (el, binding) {
        if (binding.value !== undefined) {
            el.classList.toggle("ion-valid", binding.value.valid);
            el.classList.toggle("ion-invalid", !binding.value.valid);
        }
    },
});
/*----- init router and mount app -----*/
router_1.default.isReady().then(function () {
    app.mount("#app");
});
