"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-ebc5ac1a"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { key: 1 };
var Translator_1 = require("@/plugins/Translator");
var vue_3 = require("@ionic/vue");
var TimeRecordsController_1 = require("@/controllers/modules/zeitwirtschaft/TimeRecordsController");
var DateFormatter_1 = require("@/plugins/DateFormatter");
var TimeRecordCheckInModal_vue_1 = require("@/components/modules/zeitwirtschaft/timerecords/TimeRecordCheckInModal.vue");
var vue_4 = require("vue");
/*----- texts -----*/
exports.default = (0, vue_1.defineComponent)({
    __name: 'CurrentTimeRecord',
    setup: function (__props) {
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var loggedIn = (0, vue_4.computed)(function () { return i18n.t("modules.zew.currentTimeRecord.loggedIn"); });
        var loggedOut = (0, vue_4.computed)(function () { return i18n.t("modules.zew.currentTimeRecord.loggedOut"); });
        var controller = new TimeRecordsController_1.default();
        var _a = controller.useCurrentQuery(), record = _a.data, isLoading = _a.isLoading;
        var isOpen = (0, vue_4.ref)(false);
        var currentDateTime = (0, vue_4.ref)();
        (0, vue_4.onMounted)(function () {
            setInterval(function () {
                currentDateTime.value = DateFormatter_1.default.formatDateTime(new Date());
            }, 1000);
        });
        var openModal = function (open) {
            isOpen.value = open;
        };
        /*const getText = (record: ITimeRecord) => {
          const text = TimeRecordsController.isActive(record) ? `${loggedIn.value} ${DateFormatter.formatTime(record?.time)}` : `${loggedOut.value}`
          return text;
        }*/
        var loggedInOutText = (0, vue_4.computed)(function () {
            var _a;
            var text = TimeRecordsController_1.default.isActive(record.value) ? "".concat(loggedIn.value, " ").concat(DateFormatter_1.default.formatTime((_a = record.value) === null || _a === void 0 ? void 0 : _a.time)) : "".concat(loggedOut.value);
            return text;
        });
        return function (_ctx, _cache) {
            var _a;
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                ((0, vue_2.unref)(isLoading))
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonCard), { key: 0 }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCardHeader), null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSkeletonText), {
                                        animated: true,
                                        style: { "width": "80px" }
                                    })
                                ]; }),
                                _: 1
                            }),
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCardContent), null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSkeletonText), { animated: true }),
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSkeletonText), { animated: true })
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    }))
                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCard), {
                            button: true,
                            color: ((_a = (0, vue_2.unref)(record)) === null || _a === void 0 ? void 0 : _a.status) === 1 ? 'success' : 'danger',
                            onClick: _cache[0] || (_cache[0] = function ($event) { return (openModal(true)); }),
                            class: "current-timerecord-card"
                        }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCardHeader), null, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCardTitle), null, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(currentDateTime.value), 1)
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCardSubtitle), null, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(loggedInOutText)), 1)
                                            ]; }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCardContent))
                            ]; }),
                            _: 1
                        }, 8, ["color"])
                    ])),
                (isOpen.value)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(TimeRecordCheckInModal_vue_1.default, {
                        key: 2,
                        record: (0, vue_4.ref)((0, vue_2.unref)(record)),
                        "is-open": isOpen.value,
                        onOpenModal: openModal
                    }, null, 8, ["record", "is-open"]))
                    : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
