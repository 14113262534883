"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var ApiService_1 = require("@/services/ApiService");
var Auth_1 = require("@/stores/Auth");
var Translator_1 = require("@/plugins/Translator");
var ModuleList_1 = require("@/models/settings/ModuleList");
var Notifier_1 = require("@/plugins/notifications/Notifier");
var DateFormatter_1 = require("@/plugins/DateFormatter");
var ModuleSyncError_1 = require("@/models/Errors/ModuleSyncError");
/**
 * Parent Klasse für alle Controller
 *
 */
var ControllerBase = /** @class */ (function () {
    function ControllerBase() {
        this.authStore = (0, Auth_1.useAuthStore)();
        this.api = ApiService_1.default.getInstance();
        this.translator = Translator_1.default.getInstance();
        this.i18n = this.translator.getI18nGlobalInstance();
        this.moduleId = ModuleList_1.ModuleList.NONE;
        this.moduleRoute = "";
    }
    /**
     * Gibt den Authorization Header für HTTP Anfragen zurück
     *
     */
    ControllerBase.prototype.getAuthHeader = function () {
        return __awaiter(this, void 0, void 0, function () {
            var accessToken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authStore.getAccessToken()];
                    case 1:
                        accessToken = _a.sent();
                        if (accessToken) {
                            return [2 /*return*/, { Authorization: "Bearer ".concat(accessToken) }];
                        }
                        return [2 /*return*/, {}];
                }
            });
        });
    };
    /*----- Module Sync -----*/
    /**
     * Überprüfen, ob das Modul gerade im Synchronisierungsmodus ist
     *
     * @returns ModuleSyncError bei Fehler
     */
    ControllerBase.prototype.checkModuleStatus = function (mandantId, moduleId) {
        return __awaiter(this, void 0, void 0, function () {
            var moduleSync, translator, i18n;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getModuleSync(mandantId, moduleId)];
                    case 1:
                        moduleSync = _a.sent();
                        /*----- falls ModuleSync nicht existiert -----*/
                        if (moduleSync == undefined) {
                            translator = Translator_1.default.getInstance();
                            i18n = translator.getI18nGlobalInstance();
                            Notifier_1.default.showError({ message: i18n.t("notifications.update.error") });
                            throw new ModuleSyncError_1.default("module sync missing");
                        }
                        /*----- Überprüfen ob Modul synchronisiert wird -----*/
                        if (this.isModuleSyncing(moduleSync)) {
                            /*----- module is in sync state, cancel action -----*/
                            this.showModuleSyncError(moduleSync);
                            throw new ModuleSyncError_1.default("module is in sync state");
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Gibt das IModuleSync Modell zurück
     *
     * @remarks GET: api/mandanten/mandantId/modules/moduleId/sync
     */
    ControllerBase.prototype.getModuleSync = function (mandantId, moduleId) {
        return __awaiter(this, void 0, Promise, function () {
            var response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _b = (_a = this.api.httpClient).get;
                        _c = ["mandanten/".concat(mandantId, "/modules/").concat(moduleId, "/sync")];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 2:
                        response = _e.sent();
                        console.log("getModuleSync", response);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Gibt den bool sync zurück, verwendet für einfachere schreibweise
     *
     */
    ControllerBase.prototype.isModuleSyncing = function (moduleSync) {
        return moduleSync.sync;
    };
    /**
     * Toast für Modul Synchronisationsfehler darstellen
     *
     */
    ControllerBase.prototype.showModuleSyncError = function (moduleSync) {
        return __awaiter(this, void 0, Promise, function () {
            var translator, i18n;
            return __generator(this, function (_a) {
                translator = Translator_1.default.getInstance();
                i18n = translator.getI18nGlobalInstance();
                return [2 /*return*/, Notifier_1.default.showError({
                        duration: 8500,
                        message: i18n.t("notifications.sync.module.active", { content: DateFormatter_1.default.formatDateTime(moduleSync.start) }),
                    })];
            });
        });
    };
    /**
     * Toast für Datensatz Synchronisationsfehler darstellen
     *
     */
    ControllerBase.prototype.showEntitySyncError = function () {
        return __awaiter(this, void 0, void 0, function () {
            var translator, i18n;
            return __generator(this, function (_a) {
                translator = Translator_1.default.getInstance();
                i18n = translator.getI18nGlobalInstance();
                return [2 /*return*/, Notifier_1.default.showError({
                        duration: 8500,
                        message: i18n.t("notifications.sync.entity.error"),
                    })];
            });
        });
    };
    return ControllerBase;
}());
exports.default = ControllerBase;
