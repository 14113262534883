"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * User Klasse, wird für erweiterte Funktionalitäten benötigt
 */
var User = /** @class */ (function () {
    function User(user) {
        this.id = user.id;
        this.email = user.email;
        this.firstname = user.firstname;
        this.lastname = user.lastname;
    }
    User.prototype.getFullName = function () {
        return this.firstname + " " + this.lastname;
    };
    return User;
}());
exports.default = User;
