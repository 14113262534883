"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("@ionic/vue");
var Translator_1 = require("@/plugins/Translator");
var Auth_1 = require("@/stores/Auth");
var vue_4 = require("vue");
/*----- auth store -----*/
exports.default = (0, vue_1.defineComponent)({
    __name: 'ProfileCard',
    setup: function (__props) {
        var authStore = (0, Auth_1.useAuthStore)();
        var activeUser = (0, vue_4.computed)(function () { return authStore.activeUser; });
        /*----- texts -----*/
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var welcomeBack = (0, vue_4.computed)(function () { return i18n.t("account.welcomeBack"); });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonCard), {
                class: "profile-card",
                button: true
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCardHeader), null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonGrid), { class: "uk-container" }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRow), { class: "profile-card-row" }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCol), { size: "3" }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonAvatar), null, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonImg), {
                                                                class: "avatar",
                                                                alt: "avatar",
                                                                src: "assets/icons/avatar.svg"
                                                            })
                                                        ]; }),
                                                        _: 1
                                                    })
                                                ]; }),
                                                _: 1
                                            }),
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCol), { size: "9" }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCardTitle), null, {
                                                        default: (0, vue_2.withCtx)(function () {
                                                            var _a;
                                                            return [
                                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((_a = (0, vue_2.unref)(activeUser)) === null || _a === void 0 ? void 0 : _a.getFullName()), 1)
                                                            ];
                                                        }),
                                                        _: 1
                                                    }),
                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCardSubtitle), { class: "ion-padding-top" }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(welcomeBack)), 1)
                                                        ]; }),
                                                        _: 1
                                                    })
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
