"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("@ionic/vue");
var TodoLogsController_1 = require("@/controllers/modules/aufgabenplanung/TodoLogsController");
var DateFormatter_1 = require("@/plugins/DateFormatter");
var TodoLogItem_vue_1 = require("@/components/modules/aufgabenplanung/TodoLogItem.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TodoList',
    props: {
        todoList: null
    },
    setup: function (__props) {
        return function (_ctx, _cache) {
            var _a;
            return ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((_a = __props.todoList) === null || _a === void 0 ? void 0 : _a.pages, function (group, index) {
                return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", { key: index }, [
                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(TodoLogsController_1.default).formatTodoLogs(group), function (day) {
                        return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", { key: day }, [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonText), {
                                class: "uk-padding-small uk-margin-remove-bottom",
                                color: "medium"
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(DateFormatter_1.default).formatDateShort(day[1][0].inDate)), 1)
                                ]; }),
                                _: 2
                            }, 1024),
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonList), {
                                lines: "none",
                                class: "uk-margin-large uk-margin-remove-top uk-padding-remove"
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(day[1], function (todo) {
                                        return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                            key: todo.todoLogId
                                        }, [
                                            (0, vue_2.createVNode)(TodoLogItem_vue_1.default, { todo: todo }, null, 8, ["todo"])
                                        ]));
                                    }), 128))
                                ]; }),
                                _: 2
                            }, 1024)
                        ]));
                    }), 128))
                ]));
            }), 128));
        };
    }
});
