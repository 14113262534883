"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-680da5bc"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "uk-container" };
var vue_3 = require("@ionic/vue");
var vue_query_1 = require("vue-query");
var TimeRecordHistory_vue_1 = require("@/components/modules/zeitwirtschaft/timerecords/TimeRecordHistory.vue");
var CurrentTimeRecord_vue_1 = require("@/components/modules/zeitwirtschaft/timerecords/CurrentTimeRecord.vue");
var QueryKeys_1 = require("@/models/settings/QueryKeys");
var vue_4 = require("vue");
var Translator_1 = require("@/plugins/Translator");
var AbsenceButton_vue_1 = require("@/components/modules/zeitwirtschaft/absences/AbsenceButton.vue");
var icons_1 = require("ionicons/icons");
var router_1 = require("@/router");
var AbsencesController_1 = require("@/controllers/modules/zeitwirtschaft/AbsencesController");
/*----- texts -----*/
exports.default = (0, vue_1.defineComponent)({
    __name: 'TimeManagementPage',
    setup: function (__props) {
        var _this = this;
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var moduleDescription = (0, vue_4.computed)(function () { return i18n.t("modules.zew.moduleDescription.title"); });
        var client = (0, vue_query_1.useQueryClient)();
        var isLoading = (0, vue_4.ref)(false);
        var controller = new AbsencesController_1.default();
        var _a = controller.usePendingAbsences(), absenceTransactions = _a.data, isTransactionLoading = _a.isLoading;
        var handleRefresh = function (event) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("refresh");
                        isLoading.value = true;
                        return [4 /*yield*/, client.refetchQueries([QueryKeys_1.QueryKeys.TIME_RECORDS_CURRENT])];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, client.refetchQueries([QueryKeys_1.QueryKeys.TIME_RECORDS_HISTORY])];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, client.refetchQueries([QueryKeys_1.QueryKeys.ABSENCES_PENDING])];
                    case 3:
                        _a.sent();
                        event.target.complete();
                        isLoading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        // TODO: notifications testing
        /*
        let transactions = toRaw(absenceTransactions.value);
        setInterval(() => {
          console.log("setInterval previous transactions: ", transactions)
          if (isTransactionLoading && absenceTransactions.value !== null && absenceTransactions.value !== undefined) {
            if (transactions && transactions.length < absenceTransactions.value?.length) {
              Notifier.showSuccess({message: "Neue Absenz", position: "top"})
            }
            transactions = toRaw(absenceTransactions.value);
            console.log("setInterval new  transactions: ", transactions)
        
          }
        }, 10000)
        */
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonPage), { class: "transition-preserve" }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonHeader), null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonToolbar), { fixed: true }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTitle), null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(moduleDescription)), 1)
                                        ]; }),
                                        _: 1
                                    }),
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButtons), { slot: "end" }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonNavLink), { "router-direction": "forward" }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButton), {
                                                        onClick: _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.unref)(router_1.default).push({ name: 'zewNotifications' })); })
                                                    }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonIcon), {
                                                                size: "large",
                                                                color: (0, vue_2.unref)(isTransactionLoading) ? 'primary' : !!(0, vue_2.unref)(absenceTransactions) && (0, vue_2.unref)(absenceTransactions).length > 0 ? 'success' : 'primary',
                                                                icon: (0, vue_2.unref)(isTransactionLoading) ? (0, vue_2.unref)(icons_1.mailOutline) : !!(0, vue_2.unref)(absenceTransactions) && (0, vue_2.unref)(absenceTransactions).length > 0 ? (0, vue_2.unref)(icons_1.mailUnreadOutline) : (0, vue_2.unref)(icons_1.mailOutline)
                                                            }, null, 8, ["color", "icon"])
                                                        ]; }),
                                                        _: 1
                                                    })
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    }),
                                    (isLoading.value)
                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonProgressBar), {
                                            key: 0,
                                            type: "indeterminate"
                                        }))
                                        : (0, vue_2.createCommentVNode)("", true)
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    }),
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), { fullscreen: true }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonHeader), { collapse: "condense" }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonToolbar), { fixed: true }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTitle), {
                                                size: "large",
                                                class: "uk-text-left"
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(moduleDescription)), 1)
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 1
                            }),
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRefresher), {
                                slot: "fixed",
                                onIonRefresh: _cache[1] || (_cache[1] = function ($event) { return (handleRefresh($event)); })
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRefresherContent))
                                ]; }),
                                _: 1
                            }),
                            (0, vue_2.createElementVNode)("div", _hoisted_1, [
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonGrid), { fixed: true }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRow), null, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCol), null, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createVNode)(CurrentTimeRecord_vue_1.default)
                                                    ]; }),
                                                    _: 1
                                                })
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRow), null, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCol), { size: "5" }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createVNode)(AbsenceButton_vue_1.default)
                                                    ]; }),
                                                    _: 1
                                                })
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRow), null, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCol), null, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(vue_2.Suspense, null, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createVNode)(TimeRecordHistory_vue_1.default)
                                                            ]; }),
                                                            _: 1
                                                        }))
                                                    ]; }),
                                                    _: 1
                                                })
                                            ]; }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                })
                            ])
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
