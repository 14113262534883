"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelButton = void 0;
var Translator_1 = require("@/plugins/Translator");
var translator = Translator_1.default.getInstance();
var i18n = translator.getI18nGlobalInstance();
/**
 * UI Button um eine Aktion abzubrechen
 */
exports.CancelButton = {
    role: "cancel",
    text: i18n.t("actions.cancel"),
};
