"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Theme Definitionen, müssen mit variables.scss übereinstimmen
 */
var Themes;
(function (Themes) {
    Themes["DARK"] = "dark";
    Themes["LIGHT"] = "light";
    Themes["DARKRED"] = "darkRed";
    Themes["DARKLIGHTGREEN"] = "darkLightGreen";
})(Themes || (Themes = {}));
exports.default = Themes;
