"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var LanguageSwitcher_vue_1 = require("@/components/settings/LanguageSwitcher.vue");
var ThemeSwitcher_vue_1 = require("@/components/settings/ThemeSwitcher.vue");
var vue_3 = require("@ionic/vue");
var LogoutButton_vue_1 = require("@/components/settings/LogoutButton.vue");
var MandantSwitcher_vue_1 = require("@/components/settings/MandantSwitcher.vue");
var AppUpdater_vue_1 = require("@/components/settings/AppUpdater.vue");
var Notifier_1 = require("@/plugins/notifications/Notifier");
var Update_1 = require("@/plugins/ServiceWorker/Update");
var vue_4 = require("vue");
var UsersController_1 = require("@/controllers/UsersController");
var Auth_1 = require("@/stores/Auth");
var Theme_1 = require("@/stores/Theme");
var Language_1 = require("@/stores/Language");
var Translator_1 = require("@/plugins/Translator");
var DivisionSwitcher_vue_1 = require("@/components/settings/DivisionSwitcher.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SettingsPage',
    setup: function (__props) {
        var _this = this;
        var refreshApp = (0, Update_1.useUpdate)().refreshApp;
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var isUpdating = (0, vue_4.ref)(false);
        var isLoading = (0, vue_4.ref)(false);
        var mainKey = (0, vue_4.ref)(0);
        var update = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("update button triggered");
                        isUpdating.value = true;
                        return [4 /*yield*/, Notifier_1.default.showSuccess({ duration: 5000, message: "Erfolgreich aktualisiert. <br/>Applikation wird neu geladen" })];
                    case 1:
                        _a.sent();
                        setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                isUpdating.value = false;
                                refreshApp();
                                return [2 /*return*/];
                            });
                        }); }, 3500);
                        return [2 /*return*/];
                }
            });
        }); };
        var handleRefresh = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var controller;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("refresh");
                        isLoading.value = true;
                        controller = new UsersController_1.default();
                        return [4 /*yield*/, controller.loadSettings()];
                    case 1:
                        _a.sent();
                        mainKey.value++;
                        event.target.complete();
                        isLoading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var handleGlobalUpdate = function (settings) { return __awaiter(_this, void 0, void 0, function () {
            var authStore, themeStore, languageStore, user, mandant, globalSettings, _a, controller;
            var _b;
            var _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        console.log("global update triggered", settings);
                        authStore = (0, Auth_1.useAuthStore)();
                        themeStore = (0, Theme_1.useThemeStore)();
                        languageStore = (0, Language_1.useLanguageStore)();
                        return [4 /*yield*/, authStore.getUser()];
                    case 1:
                        user = _f.sent();
                        return [4 /*yield*/, authStore.getCurrentMandant()];
                    case 2:
                        mandant = _f.sent();
                        if ((user === null || user === void 0 ? void 0 : user.id) == undefined || (mandant === null || mandant === void 0 ? void 0 : mandant.mandantId) == undefined) {
                            return [2 /*return*/];
                        }
                        _b = {
                            userId: user === null || user === void 0 ? void 0 : user.id
                        };
                        if (!((_c = settings.theme) !== null && _c !== void 0)) return [3 /*break*/, 3];
                        _a = _c;
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, themeStore.getTheme()];
                    case 4:
                        _a = _f.sent();
                        _f.label = 5;
                    case 5:
                        globalSettings = (_b.theme = _a,
                            _b.mandantId = (_d = settings.mandantId) !== null && _d !== void 0 ? _d : mandant.mandantId,
                            _b.language = (_e = settings.language) !== null && _e !== void 0 ? _e : languageStore.getLanguage(),
                            _b);
                        controller = new UsersController_1.default();
                        return [4 /*yield*/, controller.saveSettings(globalSettings)];
                    case 6:
                        _f.sent();
                        return [4 /*yield*/, Notifier_1.default.showSuccess({ message: i18n.t("settings.notifications.saveSuccess") })];
                    case 7:
                        _f.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonPage), null, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonHeader), null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonToolbar), null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTitle), null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("settings.title")), 1)
                                        ]; }),
                                        _: 1
                                    }),
                                    (isUpdating.value || isLoading.value)
                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonProgressBar), {
                                            key: 0,
                                            type: "indeterminate"
                                        }))
                                        : (0, vue_2.createCommentVNode)("", true)
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    }),
                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), { fullscreen: true }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonHeader), { collapse: "condense" }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonToolbar), null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTitle), { size: "large" }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("settings.title")), 1)
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 1
                            }),
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRefresher), {
                                slot: "fixed",
                                onIonRefresh: _cache[0] || (_cache[0] = function ($event) { return (handleRefresh($event)); })
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonRefresherContent))
                                ]; }),
                                _: 1
                            }),
                            ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(vue_2.Suspense, null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", { key: mainKey.value }, [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonGrid), { fixed: "" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(ThemeSwitcher_vue_1.default, { onUpdateSettings: handleGlobalUpdate }),
                                                (0, vue_2.createVNode)(LanguageSwitcher_vue_1.default, { onUpdateSettings: handleGlobalUpdate }),
                                                (0, vue_2.createVNode)(MandantSwitcher_vue_1.default, { onUpdateSettings: handleGlobalUpdate }),
                                                (0, vue_2.createVNode)(DivisionSwitcher_vue_1.default),
                                                (0, vue_2.createVNode)(AppUpdater_vue_1.default, { onUpdate: update }),
                                                (0, vue_2.createVNode)(LogoutButton_vue_1.default)
                                            ]; }),
                                            _: 1
                                        })
                                    ]))
                                ]; }),
                                _: 1
                            }))
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
