"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var ControllerBase_1 = require("@/controllers/ControllerBase");
var ModuleList_1 = require("@/models/settings/ModuleList");
var vue_query_1 = require("vue-query");
var QueryKeys_1 = require("@/models/settings/QueryKeys");
var Util_1 = require("@/plugins/Util");
var DateFormatter_1 = require("@/plugins/DateFormatter");
var vue_1 = require("vue");
var Supervisor_1 = require("@/models/Supervisor");
var User_1 = require("@/models/User");
/**
 * Controller für die Absenzen
 *
 * @see MaxWebBackend AbsencesController
 */
var AbsencesController = /** @class */ (function (_super) {
    __extends(AbsencesController, _super);
    function AbsencesController() {
        var _this = _super.call(this) || this;
        _this.moduleRoute = "modules/zeitwirtschaft/";
        _this.moduleId = ModuleList_1.ModuleList.TIME_MANAGEMENT;
        return _this;
    }
    /**
     * Gibt alle Absenzen zurück
     *
     * @remarks GET: api/modules/zeitwirtschaft/mandanten/mandantId/divisions/divisionId/users/divisionUserId/absences
     * @returns IAbsence Array
     */
    AbsencesController.prototype.getAbsences = function () {
        return __awaiter(this, void 0, Promise, function () {
            var user, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentDivisionUser()];
                    case 1:
                        user = _e.sent();
                        if (!user || !user.division)
                            return [2 /*return*/, null];
                        console.log("absence user", user);
                        _b = (_a = this.api.httpClient).get;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(user.division.mandantId, "/divisions/").concat(user.divisionId, "/users/").concat(user.divisionUserId, "/absences")];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 3:
                        response = _e.sent();
                        console.log("Absence response", response);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Gibt alle Absenzen zurück
     *
     * @remarks POST: api/modules/zeitwirtschaft/mandanten/mandantId/divisions/divisionId/absences
     * @returns IAbsence oder StatusCode bei Fehler
     */
    AbsencesController.prototype.postAbsence = function (absence) {
        return __awaiter(this, void 0, void 0, function () {
            var division, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentDivision()];
                    case 1:
                        division = _e.sent();
                        if (!division || !division.mandantId)
                            return [2 /*return*/, null];
                        _b = (_a = this.api.httpClient).post;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(division.mandantId, "/divisions/").concat(division.divisionId, "/absences"), absence];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 3:
                        response = _e.sent();
                        console.log("Absence response", response);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Gibt alle Absenz-typen des Mandanten zurück
     *
     * @returns IAbsenceType Array
     */
    AbsencesController.prototype.getAbsenceTypes = function () {
        return __awaiter(this, void 0, Promise, function () {
            var mandant, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentMandant()];
                    case 1:
                        mandant = _e.sent();
                        if (!mandant)
                            return [2 /*return*/, null];
                        _b = (_a = this.api.httpClient).get;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(mandant.mandantId, "/absence-types")];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 3:
                        response = _e.sent();
                        console.log("Absence types response", response);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Gibt alle Vorgesetzte (Supervisors) zurück
     *
     * @returns Supervisor Array
     */
    AbsencesController.prototype.getSupervisors = function () {
        return __awaiter(this, void 0, Promise, function () {
            var user, response, _a, _b, _c, supervisors, _i, _d, data;
            var _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentDivisionUser()];
                    case 1:
                        user = _f.sent();
                        console.log("supervisor user", user);
                        if (!user || !user.division)
                            return [2 /*return*/, null];
                        _b = (_a = this.api.httpClient).get;
                        _c = ["mandanten/".concat(user.division.mandantId, "/divisions/").concat(user.divisionId, "/users/").concat(user.divisionUserId, "/supervisors")];
                        _e = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_e.headers = _f.sent(),
                                _e)]))];
                    case 3:
                        response = _f.sent();
                        if (response.data == null)
                            return [2 /*return*/, null];
                        supervisors = Array();
                        for (_i = 0, _d = response.data; _i < _d.length; _i++) {
                            data = _d[_i];
                            supervisors.push(new Supervisor_1.default(data));
                        }
                        console.log("get supervisor response", response);
                        return [2 /*return*/, supervisors];
                }
            });
        });
    };
    /**
     * Gibt die offenen Absenzen zurück
     *
     * @returns Supervisor Array
     */
    AbsencesController.prototype.getPendingAbsences = function () {
        return __awaiter(this, void 0, Promise, function () {
            var user, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentDivisionUser()];
                    case 1:
                        user = _e.sent();
                        if (!user || !user.division)
                            return [2 /*return*/, null];
                        _b = (_a = this.api.httpClient).get;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(user.division.mandantId, "/divisions/").concat(user.divisionId, "/users/").concat(user.divisionUserId, "/absences/transactions/pending")];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 3:
                        response = _e.sent();
                        if (response.data == null)
                            return [2 /*return*/, null];
                        /*----- create user classes -----*/
                        response.data.forEach(function (transaction) {
                            var creator = {
                                id: "",
                                email: transaction.data.creator.email,
                                firstname: transaction.data.creator.firstname,
                                lastname: transaction.data.creator.lastname,
                            };
                            transaction.data.creator = new User_1.default(creator);
                        });
                        console.log("get pending absences response", response);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    AbsencesController.prototype.updateAbsence = function (transaction) {
        return __awaiter(this, void 0, Promise, function () {
            var user, mandantId, divisionId, divisionUserId, response, _a, _b, _c;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.authStore.getCurrentDivisionUser()];
                    case 1:
                        user = _e.sent();
                        if (!user || !user.division)
                            return [2 /*return*/, null];
                        mandantId = user.division.mandantId;
                        divisionId = user.divisionId;
                        divisionUserId = user.divisionUserId;
                        _b = (_a = this.api.httpClient).post;
                        _c = ["".concat(this.moduleRoute, "mandanten/").concat(mandantId, "/divisions/").concat(divisionId, "/users/").concat(divisionUserId, "/absences/transactions/"), transaction];
                        _d = {};
                        return [4 /*yield*/, this.getAuthHeader()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.headers = _e.sent(),
                                _d)]))];
                    case 3:
                        response = _e.sent();
                        if (response.data == null)
                            return [2 /*return*/, null];
                        console.log("update  absence response", response);
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    /**
     * Absenzen nach Erstellungsdatum gruppieren und innerhalb nach start sortieren
     *
     * @returns Absenz Liste nach Tag gruppiert
     */
    AbsencesController.formatAbsences = function (absences) {
        // deconstruct reactivity, returns the raw, original object for manipulation
        var absenceList = (0, vue_1.toRaw)(absences);
        /*----- add UI month to entry, used for grouping -----*/
        for (var _i = 0, absenceList_1 = absenceList; _i < absenceList_1.length; _i++) {
            var absence = absenceList_1[_i];
            absence.absence.month = DateFormatter_1.default.formatDbMonth(absence.absence.start);
        }
        /*----- group array by month as a map -----*/
        var months = (0, Util_1.mapGroupBy)(absenceList, function (x) { return x.absence.month; });
        return months;
    };
    /**
     * Überprüfen, ob die Absenz ganztägig ist
     *
     */
    AbsencesController.isEntireDay = function (absence) {
        if (absence === null || absence === undefined)
            return false;
        return absence.isEntireDay;
    };
    /*----- queries -----*/
    AbsencesController.prototype.useAbsenceHistoryQuery = function () {
        var _this = this;
        return (0, vue_query_1.useQuery)([QueryKeys_1.QueryKeys.ABSENCES_HISTORY], function () { return _this.getAbsences(); });
    };
    AbsencesController.prototype.usePendingAbsences = function () {
        var _this = this;
        return (0, vue_query_1.useQuery)([QueryKeys_1.QueryKeys.ABSENCES_PENDING], function () { return _this.getPendingAbsences(); }, {
            refetchOnMount: true,
        });
    };
    return AbsencesController;
}(ControllerBase_1.default));
exports.default = AbsencesController;
