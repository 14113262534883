"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LanguageSchema_1 = require("@/locales/LanguageSchema");
var vue_i18n_1 = require("vue-i18n");
var Translator = /** @class */ (function () {
    function Translator() {
        this.init();
    }
    Translator.prototype.init = function () {
        this.i18n = (0, vue_i18n_1.createI18n)({
            legacy: false,
            locale: "de",
            fallbackLocale: "de",
            messages: LanguageSchema_1.default,
            warnHtmlInMessage: "off",
            warnHtmlMessage: false,
            globalInjection: true,
        });
    };
    Translator.prototype.getI18nInstance = function () {
        return this.i18n;
    };
    Translator.prototype.getI18nGlobalInstance = function () {
        return this.i18n.global;
    };
    Translator.prototype.setLanguage = function (locale) {
        if (this.i18n.mode === "legacy") {
            this.i18n.global.locale = locale;
        }
        else {
            this.i18n.global.locale.value = locale;
        }
    };
    Translator.getInstance = function () {
        if (!Translator.instance)
            Translator.instance = new Translator();
        return Translator.instance;
    };
    return Translator;
}());
exports.default = Translator;
