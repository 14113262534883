"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var Auth_1 = require("@/stores/Auth");
var Config_1 = require("@/models/settings/Config");
var router_1 = require("@/router");
var ApiService = /** @class */ (function () {
    function ApiService() {
        var _this = this;
        this.isRefreshing = false;
        this.baseUrl = Config_1.Config.getApiEndpoint();
        this.httpClient = axios_1.default.create({
            baseURL: this.baseUrl,
            withCredentials: true,
        });
        this.httpClient.defaults.headers.common["Content-Type"] = "application/json";
        this.httpClient.interceptors.response.use(function (response) {
            //TODO: add auth header here?
            return response;
        }, function (error) { return __awaiter(_this, void 0, void 0, function () {
            var originalRequest, authStore, token, accessToken;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        originalRequest = error.config;
                        console.log("interceptor error");
                        console.log("original request", originalRequest);
                        /*----- network error - likely due to maintenance work -----*/
                        if (error.code === "ERR_NETWORK") {
                            console.log("ERR_NETWORK");
                            console.log("error", error);
                            originalRequest._retry = false;
                            return [2 /*return*/, Promise.reject(error)];
                        }
                        if (!(error.response && error.response.headers["content-type"] === "Token-Expired" && !originalRequest._retry)) return [3 /*break*/, 8];
                        if (!!this.isRefreshing) return [3 /*break*/, 7];
                        console.log("ERR_TOKEN_EXPIRED - refresh token");
                        originalRequest._retry = true;
                        this.isRefreshing = true;
                        console.table(error.response.headers);
                        console.log("ERR_TOKEN ERROR", error);
                        authStore = (0, Auth_1.useAuthStore)();
                        _a = {};
                        return [4 /*yield*/, authStore.getAccessToken()];
                    case 1:
                        _a.accessToken = _b.sent();
                        return [4 /*yield*/, authStore.getRefreshToken()];
                    case 2:
                        token = (_a.refreshToken = _b.sent(),
                            _a);
                        console.log("current token: " + token);
                        if (token.accessToken == null || token.refreshToken == null) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.refreshToken()];
                    case 3:
                        if (!!(_b.sent())) return [3 /*break*/, 5];
                        originalRequest._retry = false;
                        this.isRefreshing = false;
                        return [4 /*yield*/, router_1.default.push({ name: "welcome" })];
                    case 4:
                        _b.sent();
                        return [2 /*return*/, Promise.reject(error)];
                    case 5:
                        this.isRefreshing = false;
                        return [4 /*yield*/, authStore.getAccessToken()];
                    case 6:
                        accessToken = _b.sent();
                        originalRequest.headers.Authorization = "Bearer ".concat(accessToken);
                        return [2 /*return*/, this.httpClient(originalRequest)];
                    case 7: return [2 /*return*/, new Promise(function (resolve) {
                            console.log("ApiService await refresh token");
                            var intervalId = setInterval(function () {
                                if (!_this.isRefreshing) {
                                    console.log("ApiService clear interval");
                                    clearInterval(intervalId);
                                    resolve(_this.httpClient(originalRequest));
                                }
                            });
                        })];
                    case 8: return [2 /*return*/, Promise.reject(error)];
                }
            });
        }); });
    }
    ApiService.prototype.refreshToken = function () {
        return __awaiter(this, void 0, Promise, function () {
            var authStore, token, response, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log("calling refreshToken()");
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 7, , 8]);
                        authStore = (0, Auth_1.useAuthStore)();
                        _a = {};
                        return [4 /*yield*/, authStore.getAccessToken()];
                    case 2:
                        _a.accessToken = _b.sent();
                        return [4 /*yield*/, authStore.getRefreshToken()];
                    case 3:
                        token = (_a.refreshToken = _b.sent(),
                            _a);
                        return [4 /*yield*/, this.httpClient.post("auth/refresh-token", token)];
                    case 4:
                        response = _b.sent();
                        console.log("refresh-token response", response);
                        if (!response.data.refreshToken || !response.data.accessToken)
                            return [2 /*return*/, false];
                        console.log("new token: ", response.data);
                        return [4 /*yield*/, authStore.setRefreshToken(response.data.refreshToken)];
                    case 5:
                        _b.sent();
                        return [4 /*yield*/, authStore.setAccessToken(response.data.accessToken)];
                    case 6:
                        _b.sent();
                        return [2 /*return*/, true];
                    case 7:
                        error_1 = _b.sent();
                        console.log("refresh token error", error_1);
                        return [2 /*return*/, false];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ApiService.getInstance = function () {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService();
        }
        return ApiService.instance;
    };
    return ApiService;
}());
exports.default = ApiService;
