"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleList = void 0;
/**
 * Moduldefinitionen
 */
var ModuleList;
(function (ModuleList) {
    ModuleList[ModuleList["NONE"] = -1] = "NONE";
    ModuleList[ModuleList["TIME_MANAGEMENT"] = 1] = "TIME_MANAGEMENT";
    ModuleList[ModuleList["TODO"] = 2] = "TODO";
})(ModuleList = exports.ModuleList || (exports.ModuleList = {}));
