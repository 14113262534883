"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("@ionic/vue");
var icons_1 = require("ionicons/icons");
var vue_4 = require("vue");
var TodoLogsController_1 = require("@/controllers/modules/aufgabenplanung/TodoLogsController");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TodoFilters',
    props: {
        modelValue: null
    },
    emits: ["update:modelValue", "filter"],
    setup: function (__props, _a) {
        var _this = this;
        var emit = _a.emit;
        var controller = new TodoLogsController_1.default();
        /*----- filter variables -----*/
        var statusQuery = (0, vue_4.ref)("");
        var statusFilters = (0, vue_4.ref)(0);
        var assignedUserFilters = (0, vue_4.ref)(null);
        /*----- group variables -----*/
        var groupQuery = (0, vue_4.ref)("");
        var groupFilters = (0, vue_4.ref)(null);
        /*----- filter data -----*/
        // Gruppenfilter wird momentan noch nicht angewendet
        //const todoGroups = ref(await controller.getTodoGroups());
        var statusCodes = (0, vue_4.ref)({
            A: { id: 1, value: "A", isChecked: false },
            P: { id: 2, value: "P", isChecked: false },
            G: { id: 3, value: "G", isChecked: false }
        });
        /**
         * filter query kombinieren und Parent Funktion ausführen
         */
        var filter = function () { return __awaiter(_this, void 0, void 0, function () {
            var query;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = statusQuery.value.trim();
                        return [4 /*yield*/, update(query)];
                    case 1:
                        _a.sent();
                        emit("filter");
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * Status query aktualisieren
         */
        var updateStatus = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var status, isChecked, query;
            return __generator(this, function (_a) {
                status = event.detail.value;
                isChecked = event.detail.checked;
                query = "".concat(status, ",");
                // checkbox aktualisieren
                if (statusCodes.value) {
                    statusCodes.value[status].isChecked = isChecked;
                }
                // query aktualisieren
                if (isChecked) {
                    statusQuery.value += query;
                    statusFilters.value++;
                }
                else {
                    statusQuery.value = statusQuery.value.replace(query, "");
                    statusFilters.value--;
                }
                return [2 /*return*/];
            });
        }); };
        /**
         * Gruppen query aktualisieren
         */
        var updateGroup = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var group, isChecked, query;
            return __generator(this, function (_a) {
                group = event.detail.value;
                isChecked = event.detail.checked;
                query = "".concat(group.code, ",");
                // checkbox aktualisieren
                group.isChecked = isChecked;
                // query aktualisieren
                if (isChecked) {
                    groupQuery.value += query;
                }
                else {
                    groupQuery.value = groupQuery.value.replace(query, "");
                }
                return [2 /*return*/];
            });
        }); };
        /**
         * Kombinierter query zum Parent senden
         */
        var update = function (filterQuery) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                emit("update:modelValue", filterQuery);
                return [2 /*return*/];
            });
        }); };
        /**
         * Wird ausgeführt, wenn popover weggeklickt wird
         */
        var onWillDismiss = function (value) {
            filter();
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (statusFilters.value > 0)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonChip), { key: 0 }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), { class: "filter-chip" }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createTextVNode)("Status")
                                ]; }),
                                _: 1
                            }),
                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonBadge), { color: "dark" }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(statusFilters.value), 1)
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    }))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButton), {
                    id: "popover-button",
                    size: "small",
                    fill: "clear"
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonIcon), {
                            icon: (0, vue_2.unref)(icons_1.ellipsisHorizontalOutline),
                            color: "primary"
                        }, null, 8, ["icon"]),
                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), { color: "primary" }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createTextVNode)("Filter bearbeiten")
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonPopover), {
                    trigger: "popover-button",
                    "dismiss-on-select": false,
                    onIonPopoverWillDismiss: onWillDismiss
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), null, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonList), null, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonItem), {
                                            button: true,
                                            detail: true,
                                            id: "status-popover"
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)("Status")
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonPopover), {
                                            trigger: "status-popover",
                                            "dismiss-on-select": false,
                                            side: "end"
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), null, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonList), null, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(statusCodes.value, function (status) {
                                                                    return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonItem), {
                                                                        key: status.id
                                                                    }, {
                                                                        default: (0, vue_2.withCtx)(function () { return [
                                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonCheckbox), {
                                                                                slot: "start",
                                                                                onIonChange: updateStatus,
                                                                                value: status.value,
                                                                                checked: status.isChecked
                                                                            }, null, 8, ["value", "checked"]),
                                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), null, {
                                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(status.value), 1)
                                                                                ]; }),
                                                                                _: 2
                                                                            }, 1024)
                                                                        ]; }),
                                                                        _: 2
                                                                    }, 1024));
                                                                }), 128))
                                                            ]; }),
                                                            _: 1
                                                        })
                                                    ]; }),
                                                    _: 1
                                                })
                                            ]; }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
