"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { key: 0 };
var Translator_1 = require("@/plugins/Translator");
var vue_3 = require("@ionic/vue");
var vue_4 = require("vue");
var TimeRecordsController_1 = require("@/controllers/modules/zeitwirtschaft/TimeRecordsController");
var vue_query_1 = require("vue-query");
var QueryKeys_1 = require("@/models/settings/QueryKeys");
var Auth_1 = require("@/stores/Auth");
var UsersController_1 = require("@/controllers/UsersController");
var DateFormatter_1 = require("@/plugins/DateFormatter");
var date_fns_1 = require("date-fns");
var Notifier_1 = require("@/plugins/notifications/Notifier");
var BackButton_vue_1 = require("@/components/common/buttons/BackButton.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TimeRecordCheckInModal',
    props: {
        record: null,
        isOpen: null
    },
    emits: ["openModal"],
    setup: function (__props, _a) {
        var emit = _a.emit;
        return __awaiter(this, void 0, void 0, function () {
            var __temp, __restore, props, timeRecordsController, usersController, translator, i18n, signInOutMessage, journal, day, client, cancel, onSubmit, onWillDismiss;
            var _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        props = __props;
                        timeRecordsController = new TimeRecordsController_1.default();
                        usersController = new UsersController_1.default();
                        translator = Translator_1.default.getInstance();
                        i18n = translator.getI18nGlobalInstance();
                        signInOutMessage = function (record) {
                            return TimeRecordsController_1.default.isActive(record)
                                ? i18n.t("modules.zew.signOut")
                                : i18n.t("modules.zew.signIn");
                        };
                        (_b = (0, vue_1.withAsyncContext)(function () { return usersController.getTodaySaldo(); }), __temp = _b[0], __restore = _b[1]);
                        return [4 /*yield*/, __temp];
                    case 1:
                        journal = (__temp = _c.sent(),
                            __restore(),
                            __temp);
                        day = DateFormatter_1.default.formatDate(new Date());
                        client = (0, vue_query_1.useQueryClient)();
                        cancel = function () {
                            vue_3.modalController.dismiss();
                        };
                        onSubmit = function (record) { return __awaiter(_this, void 0, void 0, function () {
                            var store, mandant, timeRecord, newRecord, error_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log("onSubmit", record);
                                        if (!(record.value === null || record.value === "")) return [3 /*break*/, 2];
                                        store = (0, Auth_1.useAuthStore)();
                                        return [4 /*yield*/, store.getCurrentMandant()];
                                    case 1:
                                        mandant = _a.sent();
                                        console.log("no history mandant: ", mandant);
                                        if ((mandant === null || mandant === void 0 ? void 0 : mandant.mandantId) == undefined ||
                                            mandant.mandantUsers == undefined ||
                                            mandant.mandantUsers[0].leiUserId == null) {
                                            console.log("mandant ungültig");
                                            return [2 /*return*/];
                                        }
                                        record.value = {
                                            mandantId: mandant === null || mandant === void 0 ? void 0 : mandant.mandantId,
                                            status: 0,
                                            leiUserId: mandant === null || mandant === void 0 ? void 0 : mandant.mandantUsers[0].leiUserId,
                                            time: "",
                                            date: "",
                                            isDeleted: false,
                                            createdAt: (0, date_fns_1.format)(new Date(), "yyyy-MM-ddTHH:mm:ss"),
                                            modifiedAt: (0, date_fns_1.format)(new Date(), "yyyy-MM-ddTHH:mm:ss"),
                                        };
                                        _a.label = 2;
                                    case 2:
                                        timeRecord = (0, vue_4.toRaw)(record.value);
                                        console.log("timeRecord..", timeRecord);
                                        console.log("record.value", record.value);
                                        _a.label = 3;
                                    case 3:
                                        _a.trys.push([3, 5, 6, 7]);
                                        return [4 /*yield*/, timeRecordsController.postTimeRecord(timeRecord)];
                                    case 4:
                                        newRecord = _a.sent();
                                        if (newRecord == null) {
                                            console.log("post record failed");
                                        }
                                        return [3 /*break*/, 7];
                                    case 5:
                                        error_1 = _a.sent();
                                        Notifier_1.default.showError({ message: i18n.t("notifications.save.error") });
                                        return [3 /*break*/, 7];
                                    case 6:
                                        vue_3.modalController.dismiss();
                                        return [7 /*endfinally*/];
                                    case 7: return [2 /*return*/];
                                }
                            });
                        }); };
                        onWillDismiss = function (ev) {
                            if (emit == null)
                                return;
                            client.refetchQueries([QueryKeys_1.QueryKeys.TIME_RECORDS_CURRENT]);
                            client.refetchQueries([QueryKeys_1.QueryKeys.TIME_RECORDS_HISTORY]);
                            vue_3.modalController.dismiss();
                            emit("openModal", false);
                        };
                        return [2 /*return*/, function (_ctx, _cache) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(vue_3.IonModal), {
                                    "is-open": __props.isOpen,
                                    onWillDismiss: onWillDismiss
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonHeader), null, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonToolbar), null, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonTitle), null, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(day)), 1)
                                                            ]; }),
                                                            _: 1
                                                        }),
                                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButtons), { slot: "start" }, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createVNode)(BackButton_vue_1.default, {
                                                                    "default-href": "",
                                                                    onClick: cancel,
                                                                    "disable-text": true
                                                                })
                                                            ]; }),
                                                            _: 1
                                                        })
                                                    ]; }),
                                                    _: 1
                                                })
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonContent), { class: "ion-padding uk-container" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                ((0, vue_2.unref)(journal) !== null && (0, vue_2.unref)(journal) !== '')
                                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                                                        (0, vue_2.createElementVNode)("div", null, [
                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonText), null, {
                                                                default: (0, vue_2.withCtx)(function () {
                                                                    var _a;
                                                                    return [
                                                                        (0, vue_2.createTextVNode)("Saldo per " + (0, vue_2.toDisplayString)((0, vue_2.unref)(DateFormatter_1.default).formatDate((_a = (0, vue_2.unref)(journal)) === null || _a === void 0 ? void 0 : _a.date)), 1)
                                                                    ];
                                                                }),
                                                                _: 1
                                                            })
                                                        ]),
                                                        (0, vue_2.createElementVNode)("div", null, [
                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonText), null, {
                                                                default: (0, vue_2.withCtx)(function () {
                                                                    var _a;
                                                                    return [
                                                                        (0, vue_2.createTextVNode)(" Feriensaldo: " + (0, vue_2.toDisplayString)((_a = (0, vue_2.unref)(journal)) === null || _a === void 0 ? void 0 : _a.holidaysSaldo), 1)
                                                                    ];
                                                                }),
                                                                _: 1
                                                            })
                                                        ]),
                                                        (0, vue_2.createElementVNode)("div", null, [
                                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonText), null, {
                                                                default: (0, vue_2.withCtx)(function () {
                                                                    var _a;
                                                                    return [
                                                                        (0, vue_2.createTextVNode)(" Saldo: " + (0, vue_2.toDisplayString)(parseFloat((_a = (0, vue_2.unref)(journal)) === null || _a === void 0 ? void 0 : _a.saldo).toFixed(2)), 1)
                                                                    ];
                                                                }),
                                                                _: 1
                                                            })
                                                        ])
                                                    ]))
                                                    : (0, vue_2.createCommentVNode)("", true),
                                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButton), {
                                                    onClick: _cache[0] || (_cache[0] = function ($event) { return (onSubmit(__props.record)); }),
                                                    color: (0, vue_2.unref)(TimeRecordsController_1.default).isActive(__props.record.value) ? 'danger' : 'success',
                                                    class: "uk-position-center",
                                                    size: "large"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(signInOutMessage(__props.record.value)), 1)
                                                    ]; }),
                                                    _: 1
                                                }, 8, ["color"])
                                            ]; }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                }, 8, ["is-open"]));
                            }];
                }
            });
        });
    }
});
