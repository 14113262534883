"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "uk-container uk-margin-large-top" };
var vue_3 = require("@ionic/vue");
var vue_4 = require("vue");
var Translator_1 = require("@/plugins/Translator");
var zod_1 = require("@vee-validate/zod");
var zod_2 = require("zod");
var vee_validate_1 = require("vee-validate");
var AbsenceTypeSelect_vue_1 = require("@/components/modules/zeitwirtschaft/absences/add/AbsenceTypeSelect.vue");
var AbsenceSupervisor_vue_1 = require("@/components/modules/zeitwirtschaft/absences/add/AbsenceSupervisor.vue");
var AbsencesController_1 = require("@/controllers/modules/zeitwirtschaft/AbsencesController");
var Auth_1 = require("@/stores/Auth");
var Notifier_1 = require("@/plugins/notifications/Notifier");
var DateFormatter_1 = require("@/plugins/DateFormatter");
var router_1 = require("@/router");
var QueryKeys_1 = require("@/models/settings/QueryKeys");
var vue_query_1 = require("vue-query");
var InputTextarea_vue_1 = require("@/components/common/inputs/InputTextarea.vue");
var FormController_1 = require("@/controllers/FormController");
var InputToggle_vue_1 = require("@/components/common/inputs/InputToggle.vue");
var InputNumber_vue_1 = require("@/components/common/inputs/InputNumber.vue");
var date_fns_1 = require("date-fns");
var AbsenceDatePicker_vue_1 = require("@/components/modules/zeitwirtschaft/absences/add/AbsenceDatePicker.vue");
/*----- texts -----*/
exports.default = (0, vue_1.defineComponent)({
    __name: 'AddAbsenceForm',
    setup: function (__props) {
        var _this = this;
        var translator = Translator_1.default.getInstance();
        var i18n = translator.getI18nGlobalInstance();
        var startText = (0, vue_4.computed)(function () { return i18n.t("modules.zew.absence.start"); });
        var endText = (0, vue_4.computed)(function () { return i18n.t("modules.zew.absence.end"); });
        var messageText = (0, vue_4.computed)(function () { return i18n.t("modules.zew.absence.message"); });
        var allDayText = (0, vue_4.computed)(function () { return i18n.t("modules.zew.absence.entireDay"); });
        var hoursText = (0, vue_4.computed)(function () { return i18n.t("modules.zew.absence.hours"); });
        var title = (0, vue_4.computed)(function () { return i18n.t("modules.zew.absence.create"); });
        var saveText = (0, vue_4.computed)(function () { return i18n.t("actions.save"); });
        var controller = new AbsencesController_1.default();
        var client = (0, vue_query_1.useQueryClient)();
        var authStore = (0, Auth_1.useAuthStore)();
        var addAbsenceSchema = (0, zod_1.toFormValidator)(zod_2.z.object({
            type: zod_2.z.number({ required_error: "Typ wird benötigt" }),
            start: zod_2.z.string({ required_error: "Datum wird benötigt" }),
            end: zod_2.z.string({ required_error: "Datum wird benötigt" }).refine(function (value) {
                //const difference = differenceInCalendarDays(new Date(value), new Date(start.value))
                //console.log("difference", difference);
                // compare end, start date, -1 if end is before start, 0 if equal, 1 if end is after start
                if ((0, date_fns_1.compareAsc)(new Date(value), new Date(start.value)) !== -1) {
                    return true;
                }
                return false;
            }, "End Datum ist vor dem Start Datum"),
            allDay: zod_2.z.boolean({ required_error: "Error" }).refine(function (value) {
                console.log("allDay value: ", value);
                if (value == true) {
                    startOptions.presentation = "date";
                }
                return true;
            }),
            hours: zod_2.z.string().optional().refine(function (value) {
                if (!allDay.value && (value == undefined)) {
                    return false;
                }
                return true;
            }, "Anzahl Stunden müssen angegeben werden, wenn 'ganztägig' deaktiviert ist").refine(function (value) {
                if (allDay.value && value == '') {
                    return true;
                }
                if (!(!allDay.value && (value == undefined)) && value == '') {
                    return false;
                }
                return true;
            }, "Keine Buchstaben / Sonderzeichen erlaubt"),
            message: zod_2.z.string({ required_error: "Eine Beschreibung wird benötigt" }).min(1, "Eine Beschreibung wird benötigt"),
            supervisor: zod_2.z.string({ required_error: "Vorgesetzte Person wird für das Visum benötigt" })
        }));
        var _a = (0, vee_validate_1.useForm)({ validationSchema: addAbsenceSchema }), handleSubmit = _a.handleSubmit, errors = _a.errors;
        var _b = (0, vee_validate_1.useField)("type"), type = _b.value, typeMeta = _b.meta;
        var _c = (0, vee_validate_1.useField)("start"), start = _c.value, startMeta = _c.meta;
        var _d = (0, vee_validate_1.useField)("end"), end = _d.value, endMeta = _d.meta;
        var _e = (0, vee_validate_1.useField)("hours"), hours = _e.value, hoursMeta = _e.meta;
        var _f = (0, vee_validate_1.useField)("allDay"), allDay = _f.value, allDayMeta = _f.meta;
        var _g = (0, vee_validate_1.useField)("message"), message = _g.value, messageMeta = _g.meta;
        var _h = (0, vee_validate_1.useField)("supervisor"), supervisor = _h.value, supervisorMeta = _h.meta;
        var onSubmit = handleSubmit(function (values, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(_this, void 0, void 0, function () {
                var divisionUser, absence, response, error_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            console.log("add absence", values);
                            return [4 /*yield*/, authStore.getCurrentDivisionUser()];
                        case 1:
                            divisionUser = _b.sent();
                            if (divisionUser == null) {
                                //TODO: translate text
                                return [2 /*return*/, Notifier_1.default.showError({ message: "Absenz konnte nicht erstellt werden" })];
                            }
                            absence = {
                                divisionId: divisionUser === null || divisionUser === void 0 ? void 0 : divisionUser.divisionId,
                                creatorId: divisionUser === null || divisionUser === void 0 ? void 0 : divisionUser.divisionUserId,
                                supervisorId: values.supervisor,
                                absenceTypeId: values.type,
                                start: values.start,
                                end: values.end,
                                message: values.message,
                                isEntireDay: values.allDay,
                                modifiedAt: DateFormatter_1.default.formatDbTimestamp(new Date()),
                                createdAt: DateFormatter_1.default.formatDbTimestamp(new Date()),
                            };
                            if (!absence.isEntireDay) {
                                absence.hours = values.hours;
                            }
                            _b.label = 2;
                        case 2:
                            _b.trys.push([2, 4, , 5]);
                            return [4 /*yield*/, controller.postAbsence(absence)];
                        case 3:
                            response = _b.sent();
                            if (response && response.status == 201) {
                                Notifier_1.default.showSuccess({ message: "Absenz erstellt" });
                                resetForm();
                                client.refetchQueries([QueryKeys_1.QueryKeys.ABSENCES_HISTORY]);
                                return [2 /*return*/, router_1.default.go(-1)];
                            }
                            return [3 /*break*/, 5];
                        case 4:
                            error_1 = _b.sent();
                            Notifier_1.default.showError({ message: "Absenz konnte nicht erstellt werden" });
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        });
        var messageOptions = FormController_1.default.getDefaultTextareaOptions("message", messageText.value);
        var allDayOptions = FormController_1.default.getDefaultToggleOptions("allDay", allDayText.value);
        var hoursOptions = FormController_1.default.getDefaultNumberOptions("hours", hoursText.value);
        var startOptions = FormController_1.default.getDefaultDateTimeOptions("start", startText.value);
        var endOptions = FormController_1.default.getDefaultDateTimeOptions("end", endText.value);
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("form", {
                onSubmit: _cache[7] || (_cache[7] =
                    //@ts-ignore
                    function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        return ((0, vue_2.unref)(onSubmit) && (0, vue_2.unref)(onSubmit).apply(void 0, args));
                    })
            }, [
                ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(vue_2.Suspense, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonGrid), { fixed: "" }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(AbsenceTypeSelect_vue_1.default, {
                                    modelValue: (0, vue_2.unref)(type),
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.isRef)(type) ? (type).value = $event : null); }),
                                    error: (0, vue_2.unref)(errors).type,
                                    meta: (0, vue_2.unref)(typeMeta)
                                }, null, 8, ["modelValue", "error", "meta"]),
                                (0, vue_2.createVNode)(InputToggle_vue_1.default, {
                                    modelValue: (0, vue_2.unref)(allDay),
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((0, vue_2.isRef)(allDay) ? (allDay).value = $event : null); }),
                                    meta: (0, vue_2.unref)(allDayMeta),
                                    error: (0, vue_2.unref)(errors).allDay,
                                    options: (0, vue_2.unref)(allDayOptions)
                                }, null, 8, ["modelValue", "meta", "error", "options"]),
                                (0, vue_2.createVNode)(AbsenceDatePicker_vue_1.default, {
                                    modelValue: (0, vue_2.unref)(start),
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((0, vue_2.isRef)(start) ? (start).value = $event : null); }),
                                    "all-day": (0, vue_2.unref)(allDay),
                                    meta: (0, vue_2.unref)(startMeta),
                                    options: (0, vue_2.unref)(startOptions),
                                    error: (0, vue_2.unref)(errors).start
                                }, null, 8, ["modelValue", "all-day", "meta", "options", "error"]),
                                (0, vue_2.createVNode)(AbsenceDatePicker_vue_1.default, {
                                    modelValue: (0, vue_2.unref)(end),
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((0, vue_2.isRef)(end) ? (end).value = $event : null); }),
                                    "all-day": (0, vue_2.unref)(allDay),
                                    meta: (0, vue_2.unref)(endMeta),
                                    options: (0, vue_2.unref)(endOptions),
                                    error: (0, vue_2.unref)(errors).end
                                }, null, 8, ["modelValue", "all-day", "meta", "options", "error"]),
                                (!(0, vue_2.unref)(allDay))
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(InputNumber_vue_1.default, {
                                        key: 0,
                                        modelValue: (0, vue_2.unref)(hours),
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((0, vue_2.isRef)(hours) ? (hours).value = $event : null); }),
                                        meta: (0, vue_2.unref)(hoursMeta),
                                        error: (0, vue_2.unref)(errors).hours,
                                        options: (0, vue_2.unref)(hoursOptions)
                                    }, null, 8, ["modelValue", "meta", "error", "options"]))
                                    : (0, vue_2.createCommentVNode)("", true),
                                (0, vue_2.createVNode)(InputTextarea_vue_1.default, {
                                    modelValue: (0, vue_2.unref)(message),
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) { return ((0, vue_2.isRef)(message) ? (message).value = $event : null); }),
                                    meta: (0, vue_2.unref)(messageMeta),
                                    error: (0, vue_2.unref)(errors).message,
                                    options: (0, vue_2.unref)(messageOptions)
                                }, null, 8, ["modelValue", "meta", "error", "options"]),
                                (0, vue_2.createVNode)(AbsenceSupervisor_vue_1.default, {
                                    modelValue: (0, vue_2.unref)(supervisor),
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) { return ((0, vue_2.isRef)(supervisor) ? (supervisor).value = $event : null); }),
                                    error: (0, vue_2.unref)(errors).supervisor,
                                    meta: (0, vue_2.unref)(supervisorMeta)
                                }, null, 8, ["modelValue", "error", "meta"]),
                                (0, vue_2.createElementVNode)("div", _hoisted_1, [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonButton), {
                                        class: "uk-align-center",
                                        onClick: (0, vue_2.unref)(onSubmit),
                                        color: "success"
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(saveText)), 1)
                                        ]; }),
                                        _: 1
                                    }, 8, ["onClick"])
                                ])
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    fallback: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonGrid), { fixed: "" }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(7, function (i) {
                                    return (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonItem), { key: i }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonLabel), { slot: "start" }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSkeletonText), {
                                                        animated: true,
                                                        style: { "width": "10vw" }
                                                    })
                                                ]; }),
                                                _: 1
                                            }),
                                            (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonInput), { slot: "end" }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSkeletonText), { animated: true })
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 2
                                    }, 1024);
                                }), 64)),
                                (0, vue_2.createVNode)((0, vue_2.unref)(vue_3.IonSkeletonText), {
                                    class: "uk-align-center",
                                    animated: true,
                                    style: { "width": "50%" }
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }))
            ], 32));
        };
    }
});
